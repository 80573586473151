import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { useAppSelector, useAuth } from "hooks/hooks";
import {
	selectEnrichedErrors,
	selectReprocessing,
	setIsErrorDialogOpen,
} from "features/licenses/licenseAssignmentErrors";
import {
	CircularProgress,
	Grid,
	IconButton,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { HoverTooltip } from "components/Common/Tooltips";

import styles from "./LicenseAssignmentErrorDialog.module.scss";
import { LICENSE_ERRORS_EXPLANATION } from "utilities/constants/texts";
import clsx from "clsx";
import { reprocessLicenseAssignment } from "actions/licenseActions";
import { MUTEX_LICENSES } from "utilities/constants/constants";
import { TABS as USER_DRAWER_TABS } from "components/MyPeople/UserDrawer/UserDrawerTabSelector";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import useRole from "utilities/roleUtils/roleCheck";

export const LicenseAssignmentErrorDialog = () => {
	const { auth, dispatch } = useAuth();
	const licenseAssignmentErrors = useAppSelector(selectEnrichedErrors);
	const reprocessing = useAppSelector(selectReprocessing);
	const onReprocessLicenseAssignments = async (userId: string) => {
		dispatch(reprocessLicenseAssignment({ auth, id: userId, body: {} }));
	};

	const { isActionRole } = useRole();

	return (
		<PrimaryDialog
			title="License assignment errors"
			onLeaveAction={() => dispatch(setIsErrorDialogOpen(false))}
			primaryAction={() => dispatch(setIsErrorDialogOpen(false))}
			secondaryButtonText="Close"
			size="large"
		>
			<Grid container item>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>User</TableCell>
							<TableCell>Error</TableCell>
							<TableCell>Involved licenses</TableCell>
							{isActionRole && <TableCell align="center">Check status</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{licenseAssignmentErrors.length > 0 ? (
							licenseAssignmentErrors.map(
								({
									userId,
									error,
									userDisplayName,
									userMail,
									licenseDisplayName: licenseWithError = "",
									otherAssignedLicenses,
								}) => {
									const errorExplanation = LICENSE_ERRORS_EXPLANATION[
										error as keyof typeof LICENSE_ERRORS_EXPLANATION
									] ?? {
										displayName: error,
										description:
											"Unknown error, please contact support to solve this issue.",
									};
									const isReprocessing =
										reprocessing[userId]?.isReprocessing ?? false;
									const reprocessingSuccess =
										reprocessing[userId]?.reprocessingSuccess ?? false;
									return (
										<TableRow
											className={styles.tableRow}
											key={`${userId}-${licenseWithError}`}
										>
											<TableCell>
												<Grid container>
													<UserInfoDisplay
														id={userId}
														name={userDisplayName}
														mail={userMail}
														initialTab={USER_DRAWER_TABS.LICENSES}
														maxCharLength={{
															name: 25,
															mail: 35,
														}}
														variant={{
															name: "body1",
															mail: "description",
														}}
													/>
												</Grid>
											</TableCell>
											<TableCell>
												<HoverTooltip
													title={errorExplanation?.displayName ?? ""}
													description={
														errorExplanation?.description ?? ""
													}
												>
													<Typography variant="body1">
														{errorExplanation?.displayName ?? ""}
													</Typography>
												</HoverTooltip>
											</TableCell>
											<TableCell>
												<LicensesCellContent
													error={error ?? ""}
													licenseWithError={licenseWithError}
													otherAssignedLicenses={otherAssignedLicenses}
													userIsLoading={isReprocessing}
													reprocessedWithSuccess={reprocessingSuccess}
												/>
											</TableCell>
											{isActionRole && (
												<TableCell align="center">
													{isReprocessing ? (
														<IconButton disabled={true}>
															<CircularProgress size={20} />
														</IconButton>
													) : reprocessingSuccess ? (
														<IconButton disabled={true}>
															<CheckCircleIcon
																className={styles.checkIcon}
															/>
														</IconButton>
													) : (
														<IconButton
															disabled={isReprocessing}
															onClick={() =>
																onReprocessLicenseAssignments(
																	userId,
																)
															}
														>
															<RefreshIcon
																className={styles.refreshIcon}
															/>
														</IconButton>
													)}
												</TableCell>
											)}
										</TableRow>
									);
								},
							)
						) : (
							<TableRow>
								<TableCell colSpan={4}>
									<Typography variant="body1" textAlign="center">
										No license errors
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</Grid>
		</PrimaryDialog>
	);
};

interface LicensesCellProps {
	error: string;
	licenseWithError: string;
	otherAssignedLicenses: string[];
	userIsLoading: boolean;
	reprocessedWithSuccess: boolean;
}

const LicensesCellContent = ({
	error,
	licenseWithError,
	otherAssignedLicenses,
	userIsLoading,
	reprocessedWithSuccess,
}: LicensesCellProps) => {
	if (reprocessedWithSuccess) {
		return (
			<Typography variant="body1" color="text.success">
				{licenseWithError}
			</Typography>
		);
	}

	const checkIfMutex = (error: string, errorLicense: string, otherLicense: string) =>
		error === "MutuallyExclusiveViolation" &&
		MUTEX_LICENSES.some((mutexLicense) => errorLicense.includes(mutexLicense)) &&
		MUTEX_LICENSES.some((mutexLicense) => otherLicense?.includes(mutexLicense));

	const mutexLicense = otherAssignedLicenses.find((license) =>
		checkIfMutex(error, licenseWithError, license),
	);

	return (
		<>
			{!userIsLoading ? (
				<Typography
					variant="body1"
					className={clsx({
						[styles.mutexLicense]: mutexLicense,
					})}
				>
					{licenseWithError}
				</Typography>
			) : (
				<Skeleton width={100} height={24} />
			)}
			{mutexLicense && (
				<Grid container>
					{!userIsLoading ? (
						<Typography variant="body1" color="text.error">
							{mutexLicense}
						</Typography>
					) : (
						<Skeleton width={100} height={24} />
					)}
				</Grid>
			)}
		</>
	);
};
