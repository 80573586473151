import { createSlice } from "@reduxjs/toolkit";
import { fetchAggregatedData } from "actions/aggregatedDataActions";
import { RootState } from "store";
import {
	AggregatedDateSignin,
	AllRolesDetailed,
	CheckCredentialRegistrationCheckValues,
} from "types/AggregatedData";
import { DIRECTORY_SYNCHRONIZATION_ACCOUNT } from "utilities/constants/constants";

interface AggregatedDataState {
	isLoading: boolean;
	isFetching: boolean;
}

const aggregatedDataSlice = createSlice({
	name: "aggregatedData",
	initialState: { isLoading: true, isFetching: false } as AggregatedDataState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAggregatedData.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchAggregatedData.fulfilled, (state, { payload }) => ({
				...state,
				...payload,
				isLoading: false,
				isFetching: false,
			}));
	},
});

export const selectRiskDetectionDetails = (state: RootState) =>
	(state.aggregatedData as any).Check_RiskDetections?.CheckValues ?? [];

// Returns all admin roles that contain members without MFA except the directory synchronization account
export const selectAllAdminRolesWithoutMFA = (state: RootState) => {
	const allExceptSyncAccounts =
		(state.aggregatedData as any).Check_DirectoryRole?.CheckValues?.AllRolesDetailed?.filter(
			(role: AllRolesDetailed) => role?.Role !== DIRECTORY_SYNCHRONIZATION_ACCOUNT,
		) ?? [];

	return allExceptSyncAccounts.filter(
		(role: AllRolesDetailed) => role?.MembersWithoutMFA?.length > 0,
	) as AllRolesDetailed[];
};

export const selectCredentialRegistrationCheckValues = (state: RootState) =>
	(state.aggregatedData as any).Check_CredentialRegistration
		?.CheckValues as CheckCredentialRegistrationCheckValues;

export const selectAnyMFADetailsFound = (state: RootState) => {
	const anyAdminsWithoutMFA = selectAllAdminRolesWithoutMFA(state).length > 0;
	const anyCredentialRegistrationDetails = !!selectCredentialRegistrationCheckValues(state);

	return anyAdminsWithoutMFA || anyCredentialRegistrationDetails;
};

export const selectCheckSignInDetails = (state: RootState) =>
	(state.aggregatedData as any).Check_Signins?.CheckValues
		?.AggregatedDateSignins as AggregatedDateSignin[];

export const selectAggregatedData = (state: RootState) => state.aggregatedData;

export default aggregatedDataSlice.reducer;
