import { createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchSharePointStorageData, fetchExchangeData } from "actions/officeDataActions";
import { RootState } from "store";
import { SharePointStorageDataResponse, ExchangeMailboxDataResponse, SharePointSite } from "types";
import { SharePointRiskLevel } from "utilities/constants/enums";

const SharePointRiskLevelExplanation = {
	[SharePointRiskLevel.Low]: "Low risk.",
	[SharePointRiskLevel.Medium]: "Medium risk. Consider freeing up space or buying more storage.",
	[SharePointRiskLevel.High]:
		"High risk. Running out of space. Free up space or buy more storage.",
	[SharePointRiskLevel.Critical]: "Critical risk. No space left. Free up or buy more storage.",
	[SharePointRiskLevel.Overuse]: "Over quota. Free up or buy more storage.",
};

const initialState = {
	sharePointData: {
		isLoading: true,
		isFetching: false,
		data: {} as SharePointStorageDataResponse | Record<string, never>,
	},
	exchangeData: {
		isLoading: true,
		isFetching: false,
		data: {} as ExchangeMailboxDataResponse,
	},
};

const officeDataSlice = createSlice({
	name: "officeData",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// SharePoint
			.addCase(fetchSharePointStorageData.pending, (state) => {
				state.sharePointData.isFetching = true;
			})
			.addCase(fetchSharePointStorageData.fulfilled, (state, { payload }) => {
				state.sharePointData.isLoading = false;
				state.sharePointData.isFetching = false;
				state.sharePointData.data = payload;
			})
			.addCase(fetchSharePointStorageData.rejected, (state) => {
				state.sharePointData.isLoading = false;
				state.sharePointData.isFetching = false;
			})

			// Exchange
			.addCase(fetchExchangeData.pending, (state) => {
				state.exchangeData.isFetching = true;
			})
			.addCase(fetchExchangeData.fulfilled, (state, { payload }) => {
				state.exchangeData.isLoading = false;
				state.exchangeData.isFetching = false;
				state.exchangeData.data = payload;
			})
			.addCase(fetchExchangeData.rejected, (state) => {
				state.exchangeData.isLoading = false;
				state.exchangeData.isFetching = false;
			});
	},
});

export default officeDataSlice.reducer;

// Selectors
export const selectSharePointState = (state: RootState) => state.officeData.sharePointData;
export const selectSharePointData = (state: RootState) =>
	state.officeData.sharePointData.data as SharePointStorageDataResponse | undefined;
export const selectSharePointSites = (state: RootState) => {
	if (!state.officeData.sharePointData.data.CheckValues) {
		return [];
	}
	const allSites =
		(state.officeData.sharePointData.data.CheckValues[0]?.sites as SharePointSite[]) || [];

	return [...allSites].sort((a, b) => b.StorageUsageCurrent - a.StorageUsageCurrent);
};

export const selectAnySharepointData = (state: RootState) =>
	state.officeData.sharePointData.data.CheckValues?.length > 0;
export const selectAnyExchangeData = (state: RootState) =>
	state.officeData.exchangeData.data.CheckValues?.length > 0;

export const selectExchangeDataState = (state: RootState) => state.officeData.exchangeData;
export const selectExchangeData = (state: RootState) => state.officeData?.exchangeData.data;
export const selectExchangeMailboxes = (state: RootState) => {
	const allMailboxes = (state.officeData?.exchangeData.data.CheckValues ??
		[]) as ExchangeMailboxDataResponse["CheckValues"];

	// Correct sort is the one with the highest ratio of used storage to total storage
	return [...allMailboxes].sort(
		(a, b) =>
			b.TotalItemSizeInKB / b.ProhibitSendQuotaInKB -
			a.TotalItemSizeInKB / a.ProhibitSendQuotaInKB,
	);
};

export const selectSharePointStatistics = createSelector(selectSharePointData, (sharePointData) => {
	if (!sharePointData?.CheckValues) {
		return {
			totalStorageGB: 0,
			usedStorageGB: 0,
			percentOfStorageUsed: 0,
			usageRisk: "low",
		};
	}
	const totalStorageGB = Math.floor(
		sharePointData?.CheckValues[0]?.tenantStorageQuotaInMB / 1024,
	);
	const usedStorageGB = Math.floor(sharePointData?.CheckValues[0]?.tenantUsageDataInMB / 1024);
	const percentOfStorageUsed = sharePointData?.CheckValues[0]?.percentOfStorageUsed;

	const riskLevel =
		percentOfStorageUsed < 70
			? SharePointRiskLevel.Low
			: percentOfStorageUsed < 90
			? SharePointRiskLevel.Medium
			: percentOfStorageUsed < 95
			? SharePointRiskLevel.High
			: percentOfStorageUsed < 100
			? SharePointRiskLevel.Critical
			: SharePointRiskLevel.Overuse;

	return {
		totalStorageGB,
		usedStorageGB,
		percentOfStorageUsed,
		usageRisk: riskLevel,
		usageRiskText: SharePointRiskLevelExplanation[riskLevel],
	};
});

export const selectExchangeDataStatistics = createSelector(selectExchangeData, (exchangeData) => {
	if (!exchangeData?.CheckValues) {
		return {
			totalMailboxes: 0,
			overQuotaMailboxes: 0,
			warningQuotaMailboxes: 0,
		};
	}

	return exchangeData.CheckValues?.reduce(
		(acc, mailbox) => {
			const isWarning = mailbox.TotalItemSizeInKB >= mailbox.IssueWarningQuotaInKB;
			const isOverQuota = mailbox.TotalItemSizeInKB >= mailbox.ProhibitSendQuotaInKB;

			if (isOverQuota) {
				acc.overQuotaMailboxes++;
			} else if (isWarning) {
				acc.warningQuotaMailboxes++;
			}

			acc.totalMailboxes++;
			return acc;
		},
		{ totalMailboxes: 0, overQuotaMailboxes: 0, warningQuotaMailboxes: 0 },
	);
});
