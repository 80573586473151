import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import { selectAggregatedData, selectRiskDetectionDetails } from "features/aggregatedData";
import { useAppSelector } from "hooks/hooks";

import styles from "./RiskDetections.module.scss";

interface CardInputProps {
	cardStyle?: string;
	openDrawer: () => void;
}

export const RiskDetections = ({ cardStyle, openDrawer }: CardInputProps) => {
	const { isLoading } = useAppSelector(selectAggregatedData);
	const riskDetections = useAppSelector(selectRiskDetectionDetails);

	return (
		<Grid className={cardStyle} onClick={openDrawer}>
			<Typography variant="body1">Risk detections</Typography>
			<Grid container className={styles.riskyUsersContainer}>
				<RiskDetection
					value={riskDetections.ActiveRisks?.High_Risk}
					riskLevel="High"
					isLoading={isLoading}
				/>
				<Divider orientation="vertical" flexItem />
				<RiskDetection
					value={riskDetections.ActiveRisks?.Medium_Risk}
					riskLevel="Medium"
					isLoading={isLoading}
				/>
				<Divider orientation="vertical" flexItem />
				<RiskDetection
					value={riskDetections.ActiveRisks?.Low_Risk}
					riskLevel="Low"
					isLoading={isLoading}
				/>
			</Grid>
		</Grid>
	);
};

interface RiskDetectionProps {
	value: number;
	riskLevel: string;
	isLoading: boolean;
}

const RiskDetection = ({ value, riskLevel, isLoading }: RiskDetectionProps) => {
	return (
		<Grid item className={styles.riskyUsers}>
			{isLoading ? (
				<Skeleton variant="text" width={20} height={30} />
			) : (
				<>
					<Typography variant="body1" fontWeight={500}>
						{value}
					</Typography>
				</>
			)}
			<Typography variant="caption" className={styles.caption}>
				{riskLevel}
			</Typography>
		</Grid>
	);
};
