import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchSignins } from "actions/signinActions";
import dayjs from "dayjs";
import { RootState } from "store";
import { SignInLogEntry } from "types";

const signinsAdapter = createEntityAdapter<SignInLogEntry>();

const requestersSlice = createSlice({
	name: "signins",
	initialState: signinsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		fetchedDateRange: { fetchedFrom: "", fetchedTo: "" },
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSignins.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchSignins.fulfilled, (state, { meta, payload }) => {
				signinsAdapter.upsertMany(state, payload);
				state.fetchedDateRange = {
					fetchedFrom: meta.arg.params.from,
					fetchedTo: meta.arg.params.to,
				};
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectSigninLogState = (state: RootState) => state.signins;
export const selectFetchedDateRange = (state: RootState) => state.signins.fetchedDateRange;

export const signInsSelector = signinsAdapter.getSelectors(selectSigninLogState);
export const selectAllSignInsByDateRange = (from: string, to: string) =>
	createSelector(
		signInsSelector.selectAll,
		(signins) =>
			signins.filter((signin) => dayjs(signin.createdDateTime).isBetween(from, to)) ?? [],
	);

export default requestersSlice.reducer;
