import { securityIncidentSelectors } from "features/tickets/securityIncidents";
import { useAppSelector, useAuth } from "hooks/hooks";
import { ContentItem } from "./ContentItem";
import { useState } from "react";
import { SecurityIncident } from "types";
import { closeSecurityIncident, escalateSecurityIncident } from "actions/securityIncidentActions";
import { usersSelectors } from "features/users";
import { selectCustomerDetails } from "features/customer";
import { Grid } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { EscalateIncidentDialog } from "components/Dashboard/DashboardDrawer/DrawerContent/SecurityIncidentsDrawerContent/EscalateIncidentDialog";

export const SecurityIncidentsDrawerContent = () => {
	const securityIncidents = useAppSelector(securityIncidentSelectors.selectAll);
	const customerInfo = useAppSelector(selectCustomerDetails);
	const [isEscalateIncidentDialogOpen, setIsEscalateIncidentDialogOpen] = useState(false);
	const [isCloseIncidentDialogOpen, setIsCloseIncidentDialogOpen] = useState(false);
	const [selectedIncident, setSelectedIncident] = useState<SecurityIncident | undefined>(
		undefined,
	);

	const {
		dispatch,
		auth,
		auth: { account },
	} = useAuth();

	const userGraphData = useAppSelector((state) =>
		usersSelectors.selectById(state, account.idTokenClaims?.oid ?? ""),
	);

	const onCloseAlertClick = async (id: number) => {
		setSelectedIncident(
			securityIncidents.find((incident: SecurityIncident) => incident.id === id),
		);
		setIsCloseIncidentDialogOpen(true);
	};

	const onCloseIncident = async (id: number) => {
		setIsCloseIncidentDialogOpen(false);
		setSelectedIncident(undefined);
		await dispatch(
			closeSecurityIncident({
				auth,
				id,
				body: {
					status: "Resolved",
					resolution_summary: `This ticket was closed by ${userGraphData?.displayName} | ${userGraphData?.mail}`,
				},
			}),
		);
	};

	const onEscalateClick = async (id: number) => {
		setSelectedIncident(
			securityIncidents.find((incident: SecurityIncident) => incident.id === id),
		);
		setIsEscalateIncidentDialogOpen(true);
	};

	const onEscalateIncident = async (id: number) => {
		setIsEscalateIncidentDialogOpen(false);
		setSelectedIncident(undefined);
		await dispatch(
			escalateSecurityIncident({
				auth,
				id,
				body: {
					mail: userGraphData?.mail,
					displayName: userGraphData?.displayName,
					mobilePhone: userGraphData?.mobilePhone,
					status: "Open",
				},
			}),
		);
	};

	return (
		<>
			<Grid container>
				{securityIncidents.map((incident) => (
					<ContentItem
						key={incident.id}
						subject={incident.subject}
						customer_facing_status={incident.customer_facing_status}
						created_at={incident.created_at}
						updated_at={incident.updated_at}
						id={Number(incident.id)}
						isPremium={customerInfo.extendedSecurity === true}
						onEscalateClick={onEscalateClick}
						onCloseAlertClick={onCloseAlertClick}
					/>
				))}
			</Grid>
			{isCloseIncidentDialogOpen && (
				<PrimaryDialog
					title="Close incident?"
					size="small"
					primaryButtonText="Close incident"
					secondaryButtonText="Cancel"
					onLeaveAction={() => setIsCloseIncidentDialogOpen(false)}
					primaryAction={() => onCloseIncident(selectedIncident?.id ?? 0)}
					zIndex={1304}
				>
					By closing, Ironstone will no longer monitor this incident.
				</PrimaryDialog>
			)}
			{isEscalateIncidentDialogOpen && (
				<EscalateIncidentDialog
					onCloseDialog={() => setIsEscalateIncidentDialogOpen(false)}
					onEscalateIncident={onEscalateIncident}
					incident={selectedIncident}
					isPremium={customerInfo.extendedSecurity === true}
				/>
			)}{" "}
		</>
	);
};
