import { Grid, Typography } from "@mui/material";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import { selectRiskDetectionDetails } from "features/aggregatedData";
import { useAppSelector } from "hooks/hooks";
import { RiskDetection } from "types/AggregatedData";
import { RISK_EVENT_TYPES } from "utilities/constants/constants";

import styles from "./RiskDetectionsDrawerContent.module.scss";
import { useMemo } from "react";

export const RiskDetectionsDrawerContent = () => {
	const riskDetections = useAppSelector(selectRiskDetectionDetails);

	const groupedByRiskType =
		riskDetections.RiskDetections?.reduce(
			(acc: Record<string, RiskDetection[]>, user: RiskDetection) => {
				if (!acc[user.riskEventType]) {
					acc[user.riskEventType] = [];
				}
				acc[user.riskEventType].push(user);
				return acc;
			},
			{} as Record<string, RiskDetection[]>,
		) ?? {};

	return (
		<Grid container p={3}>
			{(Object.keys(groupedByRiskType) as (keyof typeof RISK_EVENT_TYPES)[]).map(
				(riskType) => (
					<Grid container item key={riskType} className={styles.riskyUsersContainer}>
						<Typography variant="h3">{RISK_EVENT_TYPES[riskType]?.label}</Typography>
						<Typography variant="description" className={styles.description}>
							{RISK_EVENT_TYPES[riskType]?.description}
						</Typography>
						<RiskyUsersList riskyUsers={groupedByRiskType[riskType]} />
					</Grid>
				),
			)}
		</Grid>
	);
};

const RiskyUsersList = ({ riskyUsers }: { riskyUsers: RiskDetection[] }) => {
	const counts = riskyUsers.reduce((acc: Record<string, number>, user) => {
		if (!acc[user.userId]) {
			acc[user.userId] = 0;
		}
		acc[user.userId]++;
		return acc;
	}, {} as Record<string, number>);

	const uniqueUsers = Array.from(new Map(riskyUsers.map((u) => [u.userId, u])).values());
	const sortedByRiskLevel = useMemo(
		() =>
			uniqueUsers.sort((a, b) =>
				b.riskLevel > a.riskLevel ? -1 : b.riskLevel < a.riskLevel ? 1 : 0,
			),
		[uniqueUsers],
	);

	return (
		<Grid container className={styles.riskyUsersList}>
			{sortedByRiskLevel.map((user) => (
				<Grid container item key={user.userId} className={styles.riskyUser}>
					<div className={styles[user.riskLevel]} />
					<UserInfoDisplay
						id={user.userId}
						name={user.userDisplayName}
						mail={user.userPrincipalName}
						maxCharLength={{ name: 20, mail: 25 }}
					/>
					<Typography variant="body1" className={styles.count}>
						{counts[user.userId]} Risky sign-in{counts[user.userId] > 1 ? "s" : ""}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
};
