import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import { selectUserDrawerState, setUserDrawerClosed } from "features/user";
import { usersSelectors } from "features/users";
import { useAppSelector, useAuth } from "hooks/hooks";
import { UserDrawerTabSelector } from "./UserDrawerTabSelector/UserDrawerTabSelector";
import { UserDrawerContent } from "./UserDrawerContent";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./UserDrawer.module.scss";

export const UserDrawer = () => {
	const { dispatch } = useAuth();
	const { isDrawerOpen, activeUserId } = useAppSelector(selectUserDrawerState);

	const users = useAppSelector(usersSelectors.selectEntities);

	const user = users[activeUserId];

	const handleCloseDrawer = () => {
		dispatch(setUserDrawerClosed());
	};

	return (
		<>
			<Drawer
				id="user-drawer"
				anchor="right"
				open={isDrawerOpen}
				className={styles.drawerWrapper}
				PaperProps={{ className: styles.drawer }}
				onClose={handleCloseDrawer}
				disableEnforceFocus
			>
				<Grid container className={styles.drawerContent}>
					<Grid item className={styles.drawerHeader}>
						<Typography variant="h3">{user?.displayName}</Typography>
						<IconButton className={styles.closeIcon} onClick={handleCloseDrawer}>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Grid container className={styles.drawerTabContainer}>
						<UserDrawerTabSelector />
					</Grid>
					<Grid container className={styles.drawerContentContainer}>
						<UserDrawerContent />
					</Grid>
				</Grid>
			</Drawer>
		</>
	);
};
export default UserDrawer;
