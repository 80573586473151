import { createSlice } from "@reduxjs/toolkit";
import { fetchSecureScore } from "actions/secureScoreActions";
import { RootState } from "store";
import { SecureScoreSummary } from "types";

interface SecureScoreInitialState {
	isLoading: boolean;
	isFetching: boolean;
	secureScore: SecureScoreSummary;
}
const secureScoreSlice = createSlice({
	name: "secureScore",
	initialState: {
		isLoading: true,
		isFetching: false,
		secureScore: {} as SecureScoreSummary,
	} as SecureScoreInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSecureScore.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchSecureScore.fulfilled, (state, { payload }) => {
				state.secureScore = payload;
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectSecureScore = (state: RootState) => state.secureScore;

export default secureScoreSlice.reducer;
