import { CircularProgress, Grid, Typography } from "@mui/material";
import { DashboardDrawerType } from "utilities/constants/enums";

import styles from "./LoadingDrawer.module.scss";

export const LoadingDrawerView = ({ drawerType }: { drawerType: DashboardDrawerType }) => {
	let extraMessage = "";
	switch (drawerType) {
		case DashboardDrawerType.SecurityIncidents:
			extraMessage = "Loading security incidents...";
			break;
		case DashboardDrawerType.RiskDetections:
			extraMessage = "Loading risk detections...";
			break;
		case DashboardDrawerType.Tickets:
			extraMessage = "Loading support tickets...";
			break;
		case DashboardDrawerType.SignIns:
			extraMessage = "Loading sign-ins, this may take a moment...";
			break;
		case DashboardDrawerType.UsersWithoutMfa:
			extraMessage = "Loading users without MFA...";
			break;
		case DashboardDrawerType.Sharepoint:
			extraMessage = "Loading SharePoint data...";
			break;
		case DashboardDrawerType.Exchange:
			extraMessage = "Loading Exchange data...";
			break;
	}

	return (
		<Grid container className={styles.loadingView}>
			<CircularProgress size={100} />
			{extraMessage && <Typography variant="h3">{extraMessage}</Typography>}
		</Grid>
	);
};
