import { Drawer, IconButton, Grid, Typography, Divider, Skeleton } from "@mui/material";
import { selectCustomerDetails } from "features/customer";
import { selectUsers, usersSelectors } from "features/users";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { useMemo } from "react";
import { User } from "types";
import { UserActivityType, UserType } from "utilities/constants/enums";
import CloseIcon from "@mui/icons-material/Close";
import { YourITSystems } from "assets/IronstoneProductLogos/YourITSystems";
import YourEmployees from "assets/IronstoneProductLogos/YourEmployees";
import SecurityIcon from "assets/IronstoneProductLogos/Security";
import BackupIcon from "assets/IronstoneProductLogos/Backup";
import SupportIcon from "assets/IronstoneProductLogos/Support";
import PrintIcon from "assets/IronstoneProductLogos/Print";
import GroupsIcon from "@mui/icons-material/Groups";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import { fetchUsers } from "actions/userActions";
import { IronstoneProductStatus } from "components/Common/IronstoneProductStatus";
import { OpenDrawerIcon } from "assets/misc/OpenDrawerIcon";
import { HoverTooltip } from "components/Common/Tooltips";
import { fetchAllDevices } from "actions/deviceActions";
import { devicesSelectors, selectDevices, selectGroupedDeviceData } from "features/devices";
import { InternalLink } from "components/Common/Links";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { EastOutlined } from "@mui/icons-material";

import styles from "./PermanentDashboardDrawer.module.scss";
import colors from "styles/utilities/_colors.scss";
import clsx from "clsx";

interface PermanentDashboardDrawerProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export const PermanentDashboardDrawer = ({ open, setOpen }: PermanentDashboardDrawerProps) => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const yourEmployeesEnabled = customerInfo?.yourEmployeesEnabled;
	const yourItSystemsEnabled = customerInfo?.yourItSystemsEnabled;
	const extendedSecurityEnabled = customerInfo?.extendedSecurity;
	const backupEnabled = customerInfo?.backup;
	const firstLineSupport = customerInfo?.employeeSupportEnabled;
	const print = customerInfo?.printEnabled;

	const users = useAppSelector(usersSelectors.selectAll);
	const devices = useAppSelector(devicesSelectors.selectAll);
	const usersState = useAppSelector(selectUsers);
	const deviceState = useAppSelector(selectDevices);
	const groupedDeviceData = useAppSelector(selectGroupedDeviceData);
	useApiOnce(fetchUsers, usersState);
	useApiOnce(fetchAllDevices, deviceState);

	const userStatistics = useMemo(() => {
		return users.reduce(
			(acc, user: User) => {
				acc.total++;
				if (user.licenses.length > 0) {
					acc.licensedUsers++;
				}
				if (
					user.signInActivityStatus === UserActivityType.Active ||
					user.signInActivityStatus === UserActivityType.NewUser
				) {
					acc.inactiveUsers++;
				}
				if (user.userType === UserType.Member) {
					acc.members++;
				} else if (user.userType === UserType.Guest) {
					acc.guest++;
				}
				return acc;
			},
			{
				total: 0,
				licensedUsers: 0,
				inactiveUsers: 0,
				members: 0,
				guest: 0,
			},
		);
	}, [users]);

	return (
		<>
			{!open && (
				<div className={styles.openDrawerButton} onClick={() => setOpen(true)}>
					<div>Tenant details</div>
					<OpenDrawerIcon color={colors.textPrimary} />
				</div>
			)}

			<Drawer
				anchor="right"
				open={open}
				variant="persistent"
				PaperProps={{ className: styles.drawer }}
				className={styles.drawerWrapper}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
					<CloseIcon />
				</IconButton>

				<Grid container className={styles.drawerContent}>
					<Grid item xs={12} pl={3}>
						<Typography className={styles.sectionTitle}>My products</Typography>
						<Grid container className={styles.productIcons}>
							<IronstoneProductStatus
								label="IT for your employees"
								active={yourEmployeesEnabled}
								icon={<YourEmployees active={yourEmployeesEnabled} />}
							/>
							<IronstoneProductStatus
								label="Your IT systems"
								active={yourItSystemsEnabled}
								icon={<YourITSystems active={yourItSystemsEnabled} />}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} className={styles.section}>
						<Typography variant="description">Addons</Typography>
						<Grid className={styles.addonsContainer}>
							<HoverableAddon
								icon={<SecurityIcon active={extendedSecurityEnabled} />}
								tooltipText={`Detect and Response is ${
									extendedSecurityEnabled ? "enabled" : "disabled"
								}`}
							/>
							<HoverableAddon
								icon={<BackupIcon active={backupEnabled} />}
								tooltipText={`Backup is ${backupEnabled ? "enabled" : "disabled"}`}
							/>
							<HoverableAddon
								icon={<SupportIcon active={firstLineSupport} />}
								tooltipText={`First line support is ${
									firstLineSupport ? "enabled" : "disabled"
								}`}
							/>
							<HoverableAddon
								icon={<PrintIcon active={print} />}
								tooltipText={`Print is ${print ? "enabled" : "disabled"}`}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider orientation="horizontal" className={styles.divider} flexItem />
					</Grid>
					<StatsSection
						title="Users"
						isLoading={usersState.isLoading}
						icon={<GroupsIcon className={styles.icon} />}
						total={userStatistics.total}
						stats={[
							{ label: "Licensed users", value: userStatistics.licensedUsers },
							{
								label: "Inactive users",
								value: userStatistics.inactiveUsers,
								critical: true,
							},
							{ label: "Members", value: userStatistics.members },
							{ label: "Guests", value: userStatistics.guest },
						]}
						link={NAVIGATION_NAMES.MY_PEOPLE.path}
					/>

					<Grid item xs={12}>
						<Divider orientation="horizontal" className={styles.divider} flexItem />
					</Grid>
					<StatsSection
						title="Device status"
						isLoading={usersState.isLoading}
						icon={<LaptopMacIcon className={styles.icon} />}
						total={devices.length}
						stats={[
							{
								label: "Compliant",
								value: groupedDeviceData.compliantDevices.length,
							},
							{
								label: "Non-compliant",
								value: groupedDeviceData.nonCompliantDevices.length,
								critical: true,
							},
							{ label: "Inactive", value: groupedDeviceData.inactiveDevices.length },
						]}
						link={NAVIGATION_NAMES.DEVICE_OVERVIEW.path}
					/>
				</Grid>
			</Drawer>
		</>
	);
};

interface StatsSectionProps {
	title: string;
	isLoading: boolean;
	icon: React.ReactNode;
	total: number;
	stats: { label: string; value: number; critical?: boolean }[];
	link: string;
}

const StatsSection = ({ title, isLoading, icon, stats, total, link }: StatsSectionProps) => {
	const { navigateToPage } = useAppNavigate();
	return (
		<Grid item xs={12} className={styles.section}>
			<Typography className={styles.sectionTitle}>{title}</Typography>
			<Grid container className={styles.statsHeader}>
				<Grid item>
					<Typography variant="body1">Total</Typography>
					{isLoading ? (
						<Skeleton variant="text" width={35} height={28} />
					) : (
						<Typography variant="h3">{total}</Typography>
					)}
				</Grid>
				{icon}
			</Grid>

			<Grid container className={styles.statsContainer}>
				{stats.map(({ label, value, critical }, index) => (
					<Grid item container justifyContent="space-between" key={`${label}-${index}`}>
						<Typography variant="body1" className={styles.statLabel}>
							{label}
						</Typography>
						{isLoading ? (
							<Skeleton variant="text" width={35} height={28} />
						) : (
							<Typography
								variant="body1"
								className={clsx(styles.statValue, {
									[styles.critical]: critical && value > 0,
								})}
							>
								{value}
							</Typography>
						)}
					</Grid>
				))}
				<InternalLink onClick={() => navigateToPage(link)}>
					<Typography variant="description" className={styles.viewLink}>
						View all {title.toLowerCase()}
						<EastOutlined className={styles.eastIcon} />
					</Typography>
				</InternalLink>
			</Grid>
		</Grid>
	);
};

interface HoverableAddonProps {
	icon: React.ReactNode;
	tooltipText: string;
}

const HoverableAddon = ({ icon, tooltipText }: HoverableAddonProps) => {
	return (
		<HoverTooltip title={tooltipText}>
			<Grid item>{icon}</Grid>
		</HoverTooltip>
	);
};
