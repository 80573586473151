import { Grid, Typography } from "@mui/material";
import { SimpleTableContainer } from "../SimpleTableContainer";

import styles from "./CompanyProducts.module.scss";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";

interface CompanyProductsProps {
	customerInfo: any;
}

export const CompanyProducts = ({ customerInfo }: CompanyProductsProps) => {
	return (
		<Grid item xs={12}>
			<Grid container spacing={7}>
				<Grid item xs={12} lg={4}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body1">Active products</Typography>
						</Grid>
						<Grid item xs={12}>
							<SimpleTableContainer
								header1="Product name"
								header2="Active?"
								inputData={[
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES,
										id: customerInfo.yourEmployeesEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.BACKUP,
										id: customerInfo.backup,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.DEVICE_MANAGEMENT,
										id: customerInfo.deviceManagementEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.PRINT,
										id: customerInfo.printEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.POS_KIOSK,
										id: customerInfo.posKioskEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.FIRST_LINE_SUPPORT,
										id: customerInfo.employeeSupportEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.EXTENDED_RESPONSE,
										id: customerInfo.extendedResponseEnabled,
									},
									{
										name: IRONSTONE_PRODUCT_DISPLAY_NAMES.DETECT_AND_RESPONSE,
										id: customerInfo.extendedSecurity,
									},
								]}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={8}>
					<Grid container spacing={2} className={styles.productDataTables}>
						<Grid item xs={12}>
							<Typography variant="body1">Product configuration</Typography>
						</Grid>
						<Grid item xs={12}>
							<SimpleTableContainer
								header1="Configuration"
								header2="Data"
								inputData={[
									{
										name: "Your employees - Active Azure AD Tenant",
										id: customerInfo.yourEmployeesActivatedAzureAdTenant,
									},
									{
										name: "Your IT systems - Active Azure AD Tenant",
										id: customerInfo.yourItSystemsActivatedAzureAdTenantIds,
									},
									{
										name: "Your IT systems - Active Azure AD Subscriptions",
										id: customerInfo.yourItSystemsActivatedAzureSubscriptions,
									},
									{
										name: "Apple Business Configuration",
										id: customerInfo.appleBusinessOrganizationId,
									},
									{
										name: "Komplett ID",
										id: customerInfo.komplettId,
									},
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
