import { Grid, Typography } from "@mui/material";

import styles from "./IronstoneProductStatus.module.scss";
import clsx from "clsx";

interface IronstoneProductStatusProps {
	active: boolean;
	label: string;
	icon: JSX.Element;
}

export const IronstoneProductStatus = ({ active, label, icon }: IronstoneProductStatusProps) => {
	return (
		<Grid container className={styles.productInfo}>
			<Grid
				container
				className={clsx(
					clsx({ [styles.activeProduct]: active, [styles.inactiveProduct]: !active }),
				)}
			>
				<div className={styles.productIcon}>{icon}</div>
				<Typography variant="body1">{label}</Typography>
			</Grid>
			<Typography className={clsx(styles.productStatusLabel, { [styles.active]: active })}>
				{active ? "Active" : "Inactive"}
			</Typography>
		</Grid>
	);
};
