import { Grid, Typography } from "@mui/material";
import { selectExchangeDataState, selectExchangeMailboxes } from "features/officeData";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { ExchangeMailboxData } from "types";
import { filesize } from "filesize";
import { memo } from "react";

import styles from "./ExchangeDrawerContent.module.scss";
import clsx from "clsx";
import { fetchExchangeData } from "actions/officeDataActions";

export const ExchangeDrawerContent = () => {
	useApiOnce(fetchExchangeData, useAppSelector(selectExchangeDataState));
	const exchangeMailboxes = useAppSelector(selectExchangeMailboxes);

	return (
		<Grid container p={3}>
			{exchangeMailboxes?.map((mailbox) => (
				<MemoizedExchangeItem key={mailbox.Guid} {...mailbox} />
			))}
		</Grid>
	);
};

const ExchangeItem = (mailbox: ExchangeMailboxData) => {
	const storageUsed = filesize(mailbox.TotalItemSizeInKB * 1024);
	const prohibitSendQuota = filesize(mailbox.ProhibitSendQuotaInKB * 1024);
	const isWarningQuota = mailbox.TotalItemSizeInKB >= mailbox.IssueWarningQuotaInKB;
	const isProhibitedToSend = mailbox.TotalItemSizeInKB >= mailbox.ProhibitSendQuotaInKB;
	const isProhibitedToReceive = mailbox.TotalItemSizeInKB >= mailbox.ProhibitSendReceiveQuotaInKB;

	return (
		<Grid container item className={styles.itemContainer}>
			<Grid item>
				<Typography variant="body1">{mailbox.DisplayName}</Typography>
				<Typography variant="description" className={styles.mail}>
					{mailbox.UserPrincipalName}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="description" display="inline" className={styles.text}>
					Storage used:
				</Typography>
				<Typography variant="description" display="inline" className={styles.number}>
					{storageUsed}
				</Typography>
				<Typography variant="description" display="inline" className={styles.text}>
					of
				</Typography>
				<Typography variant="description" display="inline" className={styles.number}>
					{prohibitSendQuota}
				</Typography>
			</Grid>
			<Grid item>
				<div className={styles.progressBarContainer}>
					<div
						className={clsx(styles.progressBar)}
						style={{
							width: `${
								(mailbox.TotalItemSizeInKB / mailbox.ProhibitSendQuotaInKB) * 100
							}%`,
						}}
					/>
				</div>
			</Grid>
			<Grid item container className={styles.quotaInfo}>
				{(isWarningQuota || isProhibitedToSend || isProhibitedToReceive) && (
					<div
						className={clsx(styles.colorDot, {
							[styles.criticalDot]: isProhibitedToSend || isProhibitedToReceive,
						})}
					/>
				)}
				<Typography
					variant="description"
					className={clsx({
						[styles.criticalText]: isProhibitedToSend,
					})}
				>
					{isProhibitedToReceive
						? "User cannot receive or send emails. User should be informed."
						: isProhibitedToSend
						? "User cannot send emails. User should be informed."
						: isWarningQuota
						? "Close to limit. User should be informed."
						: ""}
				</Typography>
			</Grid>
		</Grid>
	);
};

const MemoizedExchangeItem = memo(ExchangeItem);
