import { fetchSignins } from "actions/signinActions";
import dayjs from "dayjs";
import { selectSigninLogState, signInsSelector } from "features/signins";
import { useApiOnceWithParams, useAppSelector } from "hooks/hooks";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import { InputTextField } from "components/Common/InputTextField";
import { debounce } from "utilities/debounce";

import styles from "./SignInsDrawerContent.module.scss";
import { SignInLogEntry } from "types";
import { openInNewTab } from "utilities/openLink";
import { getName } from "country-list";
import clsx from "clsx";

export const SignInsDrawerContent = () => {
	const [searchValue, setSearchValue] = useState("");
	const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
	const from = dayjs().format("YYYY-MM-DD 00:00:00");
	const to = dayjs().format("YYYY-MM-DD 23:59:59");
	const signinsLogEntriesState = useAppSelector(selectSigninLogState);

	useApiOnceWithParams(fetchSignins, signinsLogEntriesState, {
		from,
		to,
	});

	const allSignins = useAppSelector(signInsSelector.selectAll);

	// Debounce effect for searchValue using useMemo to create the debounce function
	const debounceSearch = useMemo(() => {
		return debounce((value: string) => {
			setDebouncedSearchValue(value);
		}, 750);
	}, []);

	useEffect(() => {
		if (searchValue !== "") {
			debounceSearch(searchValue);
		} else {
			setDebouncedSearchValue("");
		}
	}, [searchValue, debounceSearch]);

	const shownSignins = useMemo(() => {
		if (!debouncedSearchValue) return allSignins;
		return allSignins.filter(
			(signin) =>
				signin.userDisplayName.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
				`${signin.location.state}, ${signin.location.city}, ${
					signin.location.countryOrRegion
				}, ${getName(signin.location.countryOrRegion)}`
					.toLowerCase()
					.includes(debouncedSearchValue.toLowerCase()) ||
				signin.userPrincipalName
					.toLowerCase()
					.includes(debouncedSearchValue.toLowerCase()) ||
				signin.appDisplayName.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
				signin.errorCode
					.toString()
					.toLowerCase()
					.includes(debouncedSearchValue.toLowerCase()) ||
				signin.failureReason.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
				signin.ipAddress.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
		);
	}, [allSignins, debouncedSearchValue]);

	return (
		<Grid container direction="column" className={styles.signinsDrawerContent}>
			<Grid item className={styles.searchContainer}>
				<InputTextField
					noLabel
					fullWidth
					placeholder="Search for user, country, city, IP, app, error reason"
					InputLabelProps={{ shrink: true }}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
				<Typography variant="description" className={styles.searchInfo}>
					Showing {shownSignins.length} <b>failed or interrupted</b> sign-ins from
					yesterday and today
				</Typography>
			</Grid>

			<Grid item className={styles.signinsList}>
				{shownSignins.map((signin) => (
					<MemoizedSignin key={signin.id} signin={signin} />
				))}
			</Grid>
		</Grid>
	);
};

const MemoizedSignin = React.memo(({ signin }: { signin: SignInLogEntry }) => {
	return (
		<Grid container key={signin.id} className={styles.signinsContainer}>
			<Grid container item className={styles.signinHeader}>
				<UserInfoDisplay
					mail={signin.userPrincipalName}
					id={signin.userId}
					name={signin.userDisplayName}
				/>
				<Typography variant="description" mt={1} className={styles.time}>
					{dayjs(signin.createdDateTime).format("DD. MMM YYYY HH:mm")}
				</Typography>
			</Grid>
			<Grid container item className={styles.signinDetails} direction="column">
				<Typography variant="description">
					<strong>App:</strong> {signin.appDisplayName}
				</Typography>
				<Typography variant="description">
					<strong>IP:</strong> {signin.ipAddress}
				</Typography>
				<Typography variant="description">
					<strong>Error:</strong> {signin.errorCode} - {signin.failureReason}
				</Typography>
				<Typography
					variant="description"
					className={clsx({ [styles.locationLink]: signin.location.city })}
					onClick={() =>
						openInNewTab(
							`https://maps.google.com/?q=${signin.location.city},${signin.location.state},${signin.location.countryOrRegion}&z=5&t=h`,
						)
					}
				>
					<strong>Location:</strong> {signin.location.city}, {signin.location.state},{" "}
					{signin.location.countryOrRegion}
				</Typography>
			</Grid>
		</Grid>
	);
});
