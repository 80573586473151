import { Grid, Typography } from "@mui/material";
import { selectSharePointSites } from "features/officeData";
import { useAppSelector } from "hooks/hooks";
import { SharePointSite } from "types";
import { filesize } from "filesize";
import { ExternalLink } from "components/Common/Links";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import dayjs from "dayjs";
import { memo } from "react";

import styles from "./SharepointDrawerContent.module.scss";

export const SharepointDrawerContent = () => {
	const sharePointSites = useAppSelector(selectSharePointSites);

	return (
		<Grid container p={3}>
			{sharePointSites?.map((site) => (
				<MemoizedSharepointItem key={site.URL} {...site} />
			))}
		</Grid>
	);
};

const SharePointItem = (site: SharePointSite) => {
	const formattedStorageUsed = filesize(site.StorageUsageCurrent * 1024 * 1024);

	return (
		<Grid container item className={styles.itemContainer}>
			<Grid container item className={styles.sharePointItemHeader}>
				<TruncatableTypography variant="body1" maxCharLength={45}>
					{site.Title ?? site.URL}
				</TruncatableTypography>
				<ExternalLink onClick={() => window.open(site.URL, "_blank")}>
					<Typography variant="description" className={styles.url}>
						Visit site
					</Typography>
				</ExternalLink>
			</Grid>
			<Grid container item>
				<Typography variant="description" display="inline" className={styles.text}>
					Last modified:
				</Typography>
				<Typography variant="description" display="inline">
					{site.LastModifiedDate
						? dayjs(site.LastModifiedDate).format("DD. MMM YYYY")
						: "N/A"}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="description" display="inline" className={styles.text}>
					Storage used:
				</Typography>
				<Typography variant="description" display="inline" className={styles.number}>
					{formattedStorageUsed}
				</Typography>
			</Grid>
		</Grid>
	);
};

const MemoizedSharepointItem = memo(SharePointItem);
