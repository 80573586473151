import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { DashboardDrawerType } from "utilities/constants/enums";

const dashboardAdapter = createEntityAdapter();

interface DashboardState {
	isLoading: boolean;
	isFetching: boolean;
	drawerType: DashboardDrawerType | null;
}

const departmentsSlice = createSlice({
	name: "dashboard",
	initialState: dashboardAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		drawerType: null,
	} as DashboardState),
	reducers: {
		setDashboardDrawer: (state, action) => {
			state.drawerType = action.payload as DashboardDrawerType;
		},
	},
});

export const selectDrawerType = (state: RootState) => state.dashboard.drawerType;
export const selectDashboard = (state: RootState) => state.dashboard;
export const { setDashboardDrawer } = departmentsSlice.actions;

export const dashboardSelectors = dashboardAdapter.getSelectors(selectDashboard);

export default departmentsSlice.reducer;
