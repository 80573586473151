const OpenDrawerIcon = ({ color = "#333333" }: { color?: string }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		strokeWidth="2"
		stroke={color}
		fill="none"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path stroke="none" d="M0 0h24v24H0z" color={color} />
		<rect x="4" y="4" width="16" height="16" rx="2" />
		<path d="M15 4v16" />
		<path d="M10 10l-2 2l2 2" />
	</svg>
);

export { OpenDrawerIcon };
