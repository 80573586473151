import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignInLogEntry } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchSignins = createAsyncThunk(
	"signins/fetch",
	apiGet<SignInLogEntry[]>(
		(authConfig, { params: { from, to } }: any) =>
			authConfig.MS_GRAPH_API_ENDPOINTS.GET_SIGNINS(from, to),
		[SCOPES.GRAPH_READ],
	),
);
