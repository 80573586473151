import { Typography, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { SecurityIncident } from "types";
import styles from "./EscalateIncidentDialog.module.scss";

interface EscalateIncidentProps {
	onCloseDialog: () => void;
	onEscalateIncident: (id: number) => void;
	incident: SecurityIncident | undefined;
	isPremium: boolean;
}

const EscalateIncidentDialog = ({
	onCloseDialog,
	onEscalateIncident,
	incident,
	isPremium,
}: EscalateIncidentProps): JSX.Element => {
	return (
		<PrimaryDialog
			title="Escalate incident to Ironstone?"
			size="medium"
			primaryButtonText="Escalate incident"
			secondaryButtonText="Cancel"
			onLeaveAction={() => onCloseDialog()}
			primaryAction={() => onEscalateIncident(incident?.id ?? 0)}
			removeContentMarginAndPadding={true}
			zIndex={1304}
		>
			<Grid container className={styles.incident}>
				<Grid container item xs={12} spacing={3}>
					<Grid item xs={1.5}>
						<Typography variant="body1">{`Incident: `}</Typography>
					</Grid>
					<Grid item xs={10.5}>
						<Typography variant="body1">{incident?.subject}</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={3} className={styles.incidentInfoLine}>
					<Grid item xs={1.5}>
						<Typography variant="body1">{`Severity:`}</Typography>
					</Grid>
					<Grid item xs={10.5}>
						<Typography variant="body1">{`High`}</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} className={styles.incidentInfoLine}>
					<Grid item xs={1.5}>
						<Typography variant="body1">{`Ticket ID:`}</Typography>
					</Grid>
					<Grid item xs={10.5} className={styles.ticketID}>
						<Typography variant="body1">{incident?.id}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={1} className={styles.consultancyRateInfo}>
				<Grid item xs={12}>
					<Typography variant="body2">Consultancy rate for incident handling</Typography>
				</Grid>
				<Grid item xs={12}>
					{isPremium ? (
						<>
							<Typography
								variant="body1"
								className={styles.noStartupCost}
								display="inline"
							>
								Start-up cost: NOK 4 500,-
							</Typography>
							<Typography variant="body1" display="inline">
								(No startup cost)
							</Typography>
						</>
					) : (
						<Typography variant="body1">Start-up cost: NOK 4 500,-</Typography>
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">Hourly rate: NOK 2 000,-</Typography>
				</Grid>
			</Grid>
			<Grid container className={styles.alertInfo}>
				<Grid container item xs={12} spacing={3}>
					<Grid item>
						<InfoIcon fontSize="large" className={styles.icon} />
					</Grid>
					<Grid item>
						<Typography variant="body1" className={styles.alignText}>
							By escalating incident to Ironstone, you agree to the terms above
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</PrimaryDialog>
	);
};

export { EscalateIncidentDialog };
