import { Grid, Typography } from "@mui/material";
import { DashboardDrawerType } from "utilities/constants/enums";

import styles from "./NoDataFoundDrawer.module.scss";

export const NoDataFoundDrawerView = ({ drawerType }: { drawerType: DashboardDrawerType }) => {
	let extraMessage = "";
	switch (drawerType) {
		case DashboardDrawerType.SecurityIncidents:
			extraMessage = "No security incidents found.";
			break;
		case DashboardDrawerType.RiskDetections:
			extraMessage = "No risk detections found.";
			break;
		case DashboardDrawerType.Tickets:
			extraMessage = "No support tickets found.";
			break;
		case DashboardDrawerType.SignIns:
			extraMessage = "No sign-ins found.";
			break;
		case DashboardDrawerType.UsersWithoutMfa:
			extraMessage = "No users without MFA found.";
			break;
		case DashboardDrawerType.Sharepoint:
			extraMessage = "No Sharepoint data found.";
			break;
		case DashboardDrawerType.Exchange:
			extraMessage = "No Exchange data found.";
			break;
	}

	return (
		<Grid container className={styles.noDataFoundView}>
			{extraMessage && <Typography variant="h3">{extraMessage}</Typography>}
		</Grid>
	);
};
