import { Divider, Grid } from "@mui/material";
import { InsightCard } from "./InsightCard";
import { useApi, useApiOnce, useAppSelector, useAuth, useWindowSize } from "hooks/hooks";
import { setDashboardDrawer } from "features/dashboard";
import { DashboardDrawerType } from "utilities/constants/enums";
import { DashboardDrawer } from "./DashboardDrawer";
import { RiskDetections } from "./Cards/RiskDetections";
import { SecurityIncidentsCard } from "./Cards/SecurityIncidentsCard";
import { SignInsCard } from "./Cards/SigninsCard";
import { SupportTicketsCard } from "./Cards/SupportTicketsCard/SupportTicketsCard";
import { UsersWithoutMfaCard } from "./Cards/UsersWithoutMFACard";
import { SharepointCard } from "./Cards/SharepointCard";
import { ExchangeCard } from "./Cards/ExchangeCard";
import { fetchAggregatedData } from "actions/aggregatedDataActions";
import { selectAggregatedData } from "features/aggregatedData";
import { fetchAllCosts } from "actions/costActions";
import { selectTotalCost } from "features/costs";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { selectRecommendations, selectTotalyearlyCostSavings } from "features/recommendations";
import { fetchScheduledActions } from "actions/scheduledActionActions";
import { PermanentDashboardDrawer } from "./PermanentDashboardDrawer";
import { fetchSecureScore } from "actions/secureScoreActions";
import { selectSecureScore } from "features/secureScore";
import { useEffect, useState } from "react";
import { theme } from "theme";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

import colors from "styles/utilities/_colors.scss";
import styles from "./Dashboard.module.scss";

export const Dashboard = () => {
	const { dispatch } = useAuth();

	const handleOpenDrawer = (drawerType: DashboardDrawerType) => {
		dispatch(setDashboardDrawer(drawerType));
	};
	const totalCostState = useAppSelector(selectTotalCost);
	let totalCostTrend =
		(totalCostState.totalCost - totalCostState.totalCostPreviousMonth) /
		totalCostState.totalCostPreviousMonth;
	isNaN(totalCostTrend) && (totalCostTrend = 0);

	const recommendationsState = useAppSelector(selectRecommendations);
	const totalPossibleSavings = useAppSelector(selectTotalyearlyCostSavings) ?? 0;
	const secureScoreState = useAppSelector(selectSecureScore);
	const secureScore = secureScoreState?.secureScore;

	useApiOnce(fetchAllCosts, totalCostState);
	useApiOnce(fetchAggregatedData, useAppSelector(selectAggregatedData));
	useApiOnce(fetchSecureScore, secureScoreState);
	useApi(fetchScheduledActions);

	const [open, setOpen] = useState(false);
	const { width: windowWidth } = useWindowSize();
	useEffect(() => {
		if (windowWidth > theme.breakpoints.values.md) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [windowWidth]);

	const marginRight = windowWidth > theme.breakpoints.values.md && open ? "300px" : "0";

	return (
		<>
			<Grid container className={styles.dashboard} marginRight={marginRight}>
				<Grid item className={styles.firstRow}>
					<InsightCard
						title="Total predicted this period"
						icon={Dollar}
						value={formatCostString("KR ", totalCostState?.totalCost?.toFixed(0), "")}
						trendConsideredPositive={totalCostTrend <= 0}
						trend={totalCostTrend > 0 ? "up" : "down"}
						trendValue={`${Math.round(totalCostTrend * 100)} %`}
						secondaryText="from last period"
						isLoading={totalCostState.isLoading}
						linkToPage={NAVIGATION_NAMES.COST_MANAGEMENT.path}
					/>
					<Divider orientation="vertical" flexItem className={styles.divider} />
					<InsightCard
						title="Potential yearly savings"
						icon={Idea}
						per="/ year"
						value={formatCostString("KR ", totalPossibleSavings ?? 0, "")}
						secondaryText="Based on unused licenses"
						isLoading={recommendationsState.isLoading}
						linkToPage={NAVIGATION_NAMES.RECOMMENDATIONS.path}
					/>
					<Divider orientation="vertical" flexItem className={styles.divider} />
					<InsightCard
						title="Secure score"
						icon={Shield}
						value={`${Math.round(secureScore?.currentPercentageScore ?? 0)} %`}
						trend={secureScore?.percentageChange > 0 ? "up" : "down"}
						trendConsideredPositive={secureScore?.percentageChange > 0}
						trendValue={`${Math.round(secureScore?.percentageChange ?? 0)} %`}
						secondaryText="from 30 days ago"
						isLoading={secureScoreState.isLoading}
					/>
				</Grid>
				<Grid item className={styles.secondRow}>
					<Grid item className={styles.leftColumn}>
						<Grid container className={styles.smallCards}>
							<SecurityIncidentsCard
								cardStyle={styles.smallCard}
								openDrawer={() =>
									handleOpenDrawer(DashboardDrawerType.SecurityIncidents)
								}
							/>
							<RiskDetections
								cardStyle={styles.smallCard}
								openDrawer={() =>
									handleOpenDrawer(DashboardDrawerType.RiskDetections)
								}
							/>
						</Grid>
						<SupportTicketsCard
							cardStyle={styles.supportTickets}
							openDrawer={() => handleOpenDrawer(DashboardDrawerType.Tickets)}
						/>
						<SignInsCard
							cardStyle={styles.signIns}
							openDrawer={() => handleOpenDrawer(DashboardDrawerType.SignIns)}
						/>
					</Grid>
					<Grid item className={styles.rightColumn}>
						<UsersWithoutMfaCard
							cardStyle={styles.usersWithoutMfa}
							openDrawer={() => handleOpenDrawer(DashboardDrawerType.UsersWithoutMfa)}
						/>
						<SharepointCard
							cardStyle={styles.sharepoint}
							openDrawer={() => handleOpenDrawer(DashboardDrawerType.Sharepoint)}
						/>
						<ExchangeCard
							cardStyle={styles.exchange}
							openDrawer={() => handleOpenDrawer(DashboardDrawerType.Exchange)}
						/>
					</Grid>
				</Grid>
			</Grid>
			<PermanentDashboardDrawer open={open} setOpen={setOpen} />
			<DashboardDrawer />
		</>
	);
};

const Dollar = (
	<svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.75 0H2.75C1.2375 0 0 1.215 0 2.7V24.3C0 25.785 1.2375 27 2.75 27H19.25C20.7625 27 22 25.785 22 24.3V8.1L13.75 0ZM15.125 12.15H9.625V13.5H13.75C14.5063 13.5 15.125 14.1075 15.125 14.85V18.9C15.125 19.6425 14.5063 20.25 13.75 20.25H12.375V21.6H9.625V20.25H6.875V17.55H12.375V16.2H8.25C7.49375 16.2 6.875 15.5925 6.875 14.85V10.8C6.875 10.0575 7.49375 9.45 8.25 9.45H9.625V8.1H12.375V9.45H15.125V12.15Z"
			fill={colors.dashboardBlue}
		/>
	</svg>
);

const Idea = (
	<svg width="30" height="60" viewBox="0 0 57 54" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M49.7635 10.1032L46.2093 6.549L41.6972 11.0863L45.2514 14.6406L49.7635 10.1032ZM30.984 7.80937L25.9425 7.80937V0.24715L30.984 0.24715V7.80937ZM56.1914 30.496H48.6292V25.4545H56.1914V30.496ZM25.9425 48.0152L25.9425 38.033L23.4218 36.571C20.2961 34.7561 18.3803 31.4287 18.3803 27.8492C18.3803 22.2784 22.8924 17.7663 28.4633 17.7663C34.0341 17.7663 38.5462 22.2784 38.5462 27.8492C38.5462 31.4287 36.6053 34.7561 33.5048 36.571L30.984 38.033L30.984 48.0152H25.9425ZM20.9011 53.0566L36.0255 53.0566V40.9319C40.5376 38.3103 43.5877 33.4453 43.5877 27.8492C43.5877 19.5056 36.8069 12.7248 28.4633 12.7248C20.1196 12.7248 13.3388 19.5056 13.3388 27.8492C13.3388 33.4453 16.3889 38.3103 20.9011 40.9319V53.0566ZM8.29736 30.496H0.735141V25.4545H8.29736V30.496ZM15.2546 11.0611L10.7425 6.52379L7.18823 10.078L11.7256 14.5902L15.2546 11.0611Z"
			fill={colors.yellowTint3}
		/>
	</svg>
);

const Shield = (
	<svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.6667 29.1667C8.28819 28.316 5.49889 26.3774 3.29875 23.3508C1.09861 20.3243 -0.000971578 16.9643 6.4414e-07 13.2708V4.375L11.6667 0L23.3333 4.375V13.2708C23.3333 16.9653 22.2338 20.3258 20.0346 23.3523C17.8354 26.3788 15.0461 28.3169 11.6667 29.1667ZM11.6667 26.1042C14.0243 25.375 15.9931 23.9347 17.5729 21.7831C19.1528 19.6316 20.0764 17.2317 20.3438 14.5833H11.6667V3.09896L2.91667 6.38021V13.9271C2.91667 14.0972 2.94097 14.316 2.98958 14.5833H11.6667V26.1042Z"
			fill={colors.warning}
		/>
	</svg>
);
