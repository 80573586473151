import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { IdTokenClaims as TokenClaims } from "@azure/msal-common";
import { ResponseType } from "axios";
import {
	LocationEnum,
	Currency,
	OnboardingStatus,
	ActionLoadingStatus,
	LicenseActionStatus,
	CostSavingsType,
	LicenseActionOperationType,
	DownloadFileStatusEnum,
	DeliveryOption,
	TermDuration,
	CapabilityStatus,
	UserActivityType,
	IntuneManagementType,
	DeviceFilterKey,
	DeviceComplianceStatus,
	Role,
	LicenseAppliesTo,
	DocumentType,
	ApproverType,
	UserAuthenticationMethod,
	ReportCreationStatus,
} from "utilities/constants/enums";

export interface ScopeTypes {
	GRAPH_READ: string;
	GRAPH_WRITE: string;
	PARTNERCENTER_READ: string;
	PARTNERCENTER_WRITE: string;
	FRESHSERVICE_READ: string;
	FRESHSERVICE_WRITE: string;
	DATA_LAKE_READ: string;
	DATA_LAKE_WRITE: string;
	BILLING_READ: string;
	COLLABORATION_WRITE: string;
	COLLABORATION_READ: string;
	HARDWARE_WRITE: string;
	HARDWARE_READ: string;
	FUNCTION_WRITE: string;
	FUNCTION_READ: string;
	REPORTS_WRITE: string;
	REPORTS_READ: string;
	PARTNER_READ: string;
	PARTNER_WRITE: string;
	SCHEDULER_READ: string;
	SCHEDULER_WRITE: string;
}

export enum ProductType {
	DEFAULT,
	YOUR_EMPLOYEES,
	YOUR_IT_SYSTEMS,
	HARDWARE_SOLUTION, // Komplett hardware
	//Deprecated
	PEOPLE_SERVICE,
}

export const PRODUCT_TYPES = {
	[ProductType.DEFAULT]: "",
	[ProductType.YOUR_EMPLOYEES]: "IT for Your employees",
	[ProductType.YOUR_IT_SYSTEMS]: "Your IT systems",
	[ProductType.HARDWARE_SOLUTION]: "Hardware solution",
	[ProductType.PEOPLE_SERVICE]: "Your employees", // I.e users will never purchase the "People Service" anymore, always YE-package
};

export interface NavigationName {
	readonly path: string;
	readonly value: string;
	readonly label: string;
	readonly selector: string;
	readonly accessibleWithProducts: ProductType[];
	readonly accessibleByRoles: Role[];
	readonly element?: React.ReactNode;
	readonly index?: boolean;
	readonly prefixIcon?: JSX.Element | undefined;
	readonly description?: (isActionRole: boolean) => string;
}

export interface NavigationNames {
	MY_PEOPLE: NavigationName;
	DEVICE_OVERVIEW: NavigationName;
	COST_MANAGEMENT: NavigationName;
	LICENSE_COST_DETAILED: NavigationName;
	AZURE_COST_DETAILED: NavigationName;
	CONSULTANCY_COST: NavigationName;
	IT_FOR_YOUR_EMPLOYEES_COST: NavigationName;
	YOUR_IT_SYSTEMS_COST: NavigationName;
	LICENSE_MANAGEMENT: NavigationName;
	ASSIGN_LICENSES: NavigationName;
	RECOMMENDATIONS: NavigationName;
	INVOICE: NavigationName;
	SUPPORT: NavigationName;
	LOGOUT: NavigationName;
	DASHBOARD: NavigationName;
	MY_PROFILE: NavigationName;
	MY_COMPANY: NavigationName;
	COLLABORATION: NavigationName;
	COLLABORATION_REQUEST_FORM: NavigationName;
	HARDWARE: NavigationName;
	HARDWARE_CHECKOUT: NavigationName;
	HARDWARE_BUNDLES: NavigationName;
	HARDWARE_EDIT_BUNDLE: NavigationName;
	HARDWARE_CREATE_BUNDLE: NavigationName;
	ORDERS: NavigationName;
	ROOT: NavigationName;
	WILDCARD: NavigationName;
}

export interface SubHeader {
	readonly id: string;
	readonly nameForUser: string;
	readonly nameForAllOtherRoles: string;
}

export interface SubHeaders {
	readonly MY_PEOPLE: SubHeader;
}

export interface SubscriptionGroup {
	readonly id: string;
	readonly label: string;
}

export interface SubscriptionStatus {
	readonly active: SubscriptionGroup;
	readonly suspended: SubscriptionGroup;
	readonly deleted: SubscriptionGroup;
}

export interface ValidationRegexp {
	readonly USERNAME: RegExp;
	readonly MAIL_PREFIX: RegExp;
	readonly PASSWORD: RegExp;
	readonly SPLIT_NUMBER_BY_THOUSANDS: RegExp;
	readonly MAIL_COMPLETE: RegExp;
	readonly PHONE_NUMBER: RegExp;
	readonly CITY_NAME: RegExp;
	readonly POSTAL_CODE: RegExp;
	readonly INVOICE_FILE_NAME: RegExp;
}

export interface RoleAccess {
	PAGES: ReadonlyArray<NavigationName>;
	readonly DEFAULT_PAGE: NavigationName;
	readonly PRODUCTS: object;
}

export interface RolesAccess {
	ADMIN: Readonly<RoleAccess>;
	USER: Readonly<RoleAccess>;
	FINANCE: Readonly<RoleAccess>;
	HR: Readonly<RoleAccess>;
	PORTALREAD: Readonly<RoleAccess>;
}

interface RiskEvent {
	readonly label: string;
	readonly description: string;
}
export interface RiskEventTypes {
	unlikelyTravel: RiskEvent;
	anonymizedIPAddress: RiskEvent;
	maliciousIPAddress: RiskEvent;
	unfamiliarFeatures: RiskEvent;
	malwareInfectedIPAddress: RiskEvent;
	suspiciousIPAddress: RiskEvent;
	leakedCredentials: RiskEvent;
	investigationsThreatIntelligence: RiskEvent;
	generic: RiskEvent;
	adminConfirmedUserCompromised: RiskEvent;
	passwordSpray: RiskEvent;
	anomalousToken: RiskEvent;
	tokenIssuerAnomaly: RiskEvent;
	suspiciousBrowser: RiskEvent;
	impossibleTravel: RiskEvent;
	mcasImpossibleTravel: RiskEvent;
	newCountry: RiskEvent;
	riskyIPAddress: RiskEvent;
	mcasSuspiciousInboxManipulationRules: RiskEvent;
	suspiciousInboxForwarding: RiskEvent;
}

export type RiskLevel = "high" | "medium" | "low";

// aggregated data types & interfaces
export type RiskyUsersDetails = {
	riskLevel: string;
	userDisplayName: string;
	userPrincipalName: string;
	id: string;
};

export interface CheckSignIns {
	CheckValues: {
		CountLimit: number;
		Date: string;
		FailedLogonsPercentage: number;
		TotalConditionalAccessFailureLogons: number;
		TotalConditionalAccessNotAppliedLogons: number;
		TotalConditionalAccessSuccessLogons: number;
		TotalFailedLogons: number;
		TotalInterruptedLogons: number;
		TotalLogons: number;
		TotalSuccessLogons: number;
		TotalUknowncodesLogons: number;
	}[];
	Check: string;
	Date: string;
	IntDays_CheckValues: string;
}

export interface CheckRiskDetection {
	detectedDateTime: string;
	location: {
		state: string;
		city: string;
		countryOrRegion: string;
	};
	userId: string;
	userPrincipalName: string;
	riskState: string;
	userDisplayName: string;
	riskLevel: string;
	lastUpdatedDateTime: string;
	riskDetail: string;
	riskEventType: string;
}

export interface NoDuplicatesRiskDetections extends CheckRiskDetection {
	riskySignInsCount: number;
}

export type RiskySignInsCollection = {
	[T in keyof RiskEventTypes]: {
		[T in "high" | "medium" | "low"]: NoDuplicatesRiskDetections[];
	};
};

//Collaboration types start
export interface CollabType {
	type: string;
	displayName: string;
}

export interface CollabTypes {
	readonly INTERNAL_COLLABORATION: CollabType;
	readonly EXTERNAL_COLLABORATION: CollabType;
	readonly INTERNAL_PROJECT: CollabType;
	readonly EXTERNAL_PROJECT: CollabType;
}

export interface CollabGroupingType {
	readonly USERS: string;
	readonly GROUPS: string;
}

export interface CollabRoleType {
	readonly USER: string;
	readonly GROUP: string;
	readonly ROLE: string;
}

export interface CollaborationRole {
	displayName: string;
	groupingType: CollabGroupingType;
	id: string;
	type: CollabRoleType;
	userPrincipalName: string;
}

export interface SelectedUser extends CollaborationRole {
	label: string;
	mail: string;
}

export interface ArchivalSettings {
	id: string;
	groupLifeTimeInDays: number;
	managedGroupTypes: string;
	alternateNotificationEmails: string;
}

export interface CollaborationSettings {
	workspaceSettings: {
		internalProjectEnabled: boolean;
		externalProjectEnabled: boolean;
		internalCollaborationEnabled: boolean;
		externalCollaborationEnabled: boolean;
	};
	approvers: {
		externalCollaborationApprovers: Array<CollaborationRole>;
		internalCollaborationApprovers: Array<CollaborationRole>;
		internalProjectApprovers: Array<CollaborationRole>;
		externalProjectApprovers: Array<CollaborationRole>;
	};
	archivalSettings: ArchivalSettings;
	creators: {
		externalCollaborationCreators: Array<CollaborationRole>;
		externalProjectCreators: Array<CollaborationRole>;
		internalProjectCreators: Array<CollaborationRole>;
		internalCollaborationCreators: Array<CollaborationRole>;
	};
}

export interface Anomalies {
	subscriptions: Subscription[];
	resources: any[];
}

export interface Subscription {
	location?: LocationEnum;
	total: number;
	totalPreTax: number;
	isNewForThisMonth: boolean;
	lastMonthTotal: number;
	percentOfTotal: number;
	lastMonthTotalPreTax: number;
	changeFromLastMonth: number;
	percentualChangeFromLastMonth: number | null;
	changePercentOfTotal: number | null;
	preTaxChangeFromLastMonth: number;
	preTaxPercentualChangeFromLastMonth: number | null;
	resourceId?: string;
	resourceName?: string;
	serviceName?: string;
	name?: string;
	subscriptionName?: string;
	subscriptionId?: string;
	monthlyForecast?: number;
	tagName?: string;
	tagValue?: string;
	tagCombination?: string;
}

export interface ResourceGroup {
	resourceGroupName: string;
	subscriptionId: string;
	total: number;
	totalPreTax: number;
	isNewForThisMonth: boolean | number;
	lastMonthTotal: number;
	lastMonthTotalPreTax: number;
	changeFromLastMonth: number;
	percentualChangeFromLastMonth: number | null;
	preTaxChangeFromLastMonth: number;
	preTaxPercentualChangeFromLastMonth: number | null;
	percentOfTotal?: number;
	changePercentOfTotal?: number | null;
}

export interface CreateHardwareOrder {
	messageToSalesRep: string;
	purchaserFirmName: string;
	purchaserFirmAddress: string;
	purchaserFirmPostalCode: string;
	purchaserFirmCity: string;
	purchaserFirmCountryCode: string;
	contactPersonName: string;
	contactPersonEmail: string;
	contactPersonPhone: string;
	orderLines: OrderLine[];
	hardwareNote: string;
}
export interface OrderLine {
	sku: string;
	quantity: number;
}

export interface InvoiceData {
	nextInvoiceReadyOn: Date;
	invoiceLines: InvoiceLine[];
}

export interface InvoiceLine {
	TenantID: string;
	SubscriptionID: string;
	CurrencyCode: string;
	CustomerName: string;
	InvoiceNumber: string;
	InvoiceGeneratedDate: Date;
	InvoiceDueDate: Date;
	SubscriptionName: string;
	ChargeStartDate: Date;
	ChargeEndDate: Date;
	LineItemType: string;
	Quantity: number;
	UnitPrice: number;
	InvoiceLineAmount: number;
	DiscountAmount: number | null;
	TaxAmount: number;
	PreTaxAmount: number;
	PostTaxAmount: number;
	TaxPercentage: number;
	SubscriptionType: string;
	OfferCode: string;
	OfferID: string;
	CostOnPartner: number;
}

export interface StatusDetails {
	accepted: boolean;
	shipped: boolean;
	invoiced: boolean;
}

export interface TrackingLink {
	packageId: string;
	trackingUrl: string;
	productId: string;
}

export interface HardwareBundleProductInfo {
	description: string;
	displayName: string;
	sku: string;
	group: string;
	subGroup: string;
	url: string;
	imageUrl: string;
	ean: string;
	manufacturer: string;
}

export interface HardwareProduct extends HardwareBundleProductInfo {
	availability: Availability;
	priceInfo: PriceInfo;
}

export interface HardwareBundleItem extends HardwareBundleProductInfo {
	count: number;
}

export interface HardwareBundle {
	name: string;
	id: string;
	description?: string;
	products: { [key: string]: HardwareBundleItem };
	published: boolean;
	createdAt?: Date | null;
}

export interface Availability {
	inStock: boolean;
	incomingStock: string;
	quantity: string;
	availableDate: string;
	expectedDeliveryTimeInDays: string;
	availabilityConfirmed: boolean;
}

export interface PriceInfo {
	priceNetExcludingCustomerDiscount: number;
	priceNet: number;
	priceGross: number;
	shippingPrice: number;
	currency: Currency;
	discountPercentage: number;
	exactDiscountPercentage: number;
	discount: number;
	leasingFactor: number;
}

export interface ServiceStatusGroup {
	name: string;
	groupState: string;
	monitors: Monitor[];
}

export interface Monitor {
	name: string;
	state: string;
	monitorId: string;
}

export interface SecurityIncident {
	id: number;
	subject: string;
	description_text: string;
	status: string;
	customer_facing_status: string;
	created_at: string;
	updated_at: string;
	due_by: string;
	type: string;
}

export interface TicketStatistics {
	period: string;
	total: number;
	extendedSecurityTickets: number;
	supportTickets: number;
	escalatedTickets: number;
	tickets: {
		supportTickets: Ticket[];
		escalatedTickets: Ticket[];
		extendedSecurityTickets: Ticket[];
	};
}

export interface Ticket {
	id: number;
	category: string;
	sub_category: string;
	item_category: string;
	created_at: string;
	subject: string;
	description: string;
}

export interface Price {
	exclVat: number;
	inclVat: number;
}

export interface HardwareCost {
	period: string;
	totalPriceSum: number;
}

export interface TotalCost {
	yourEmployees: YourEmployeesCost[];
	yourItSystems: YourITSystemsCost[];
	azure: AzureCost[];
	office365: Office365Cost[];
	totalCost: CostOverviewTotals;
	consultancy: ConsultancyCost[];
	reservedInstances: ReservedInstancesCost[];
	marketplaceItems: MarketplaceItemsCost[];
}

export interface AzureCost {
	period: string;
	totalCost: number;
	totalForecasted: number;
	totalForecastedDiscounted: number;
	totalPreTax: number;
	totalPreTaxDiscounted: number;
	currentDailyCost: number;
	currency: string;
	anomalies: Anomalies;
	subscriptions: SubscriptionShort[];
	fromDate: string;
	toDate: string;
	lastMonthToDate: string;
	lastMonthFromDate: string;
}

export interface Office365Cost {
	period: string;
	currentDailyCost: number;
	totalForecasted: number;
	totalDiscountAmount: number;
	licenseSubscriptions: LicenseSubscriptionCostDetails[];
}

export interface ConsultancyCost {
	period: string;
	billingType: string;
	totalForecasted: Price;
	billedCost: Price;
	nonBilledCost: Price;
	totalDurationInMinutes: number;
	totalBilledDurationInMinutes: number;
	totalNonBilledDurationInMinutes: number;
	allEntries: ConsultancyCostEntries[];
}

export interface ConsultancyCostEntries {
	consultantName: string;
	consultantEmail: string;
	durationInMinutes: number;
	cost: Price;
	day: Date;
	ticketId: string;
	ticketType: string;
	ticketUrl: string;
	ticketSubject: string;
	labels: Label[];
	note: string;
	billed: boolean;
	hourRate: Price;
	projectName: string;
	invoiceId: number;
	billable: boolean;
}

interface Label {
	parentLabel: string;
	childLabel: string;
}

export interface ReservedInstancesCost {
	period: string;
	billingType: string;
	currentDailyCost: Price;
	totalForecasted: Price;
	billableLines: BillableLine[];
}

export interface MarketplaceItemsCost {
	period: string;
	billingType: string;
	currentDailyCost: Price;
	totalForecasted: Price;
	billableLines: BillableLine[];
}

export interface BillableLine {
	quantity: number;
	subscriptionId: string;
	subscriptionName: string;
	totalForecasted: Price;
	unitPrice: Price;
}

export interface YourEmployeesCost {
	period: string;
	billingType: string;
	totalCost: number;
	product_1: Product;
	product_2: Product;
	product_3: Product;
	product_4: Product;
	product_5: Product;
	product_6: Product;
	product_7: Product;
	product_8: Product;
	product_9: Product;
	product_10: Product;
	Location: string;
}

export interface Product {
	key: string;
	productNumber: string;
	displayName: string;
	description: string;
	status: string;
	dealName: string;
	billableCount: number;
	unitPrice: number;
	totalProductCost: number;
}

export interface YourITSystemsCost {
	period: string;
	billingType: string;
	totalCost: number;
	product_50: YourITSystemsProduct;
	product_51: YourITSystemsProduct;
	product_52: YourITSystemsProduct;
	product_53: YourITSystemsProduct;
	product_54: YourITSystemsProduct;
}

export interface YourITSystemsProduct {
	displayName: string;
	billableCount: number;
	unitPrice: number;
	totalProductCost: number;
}

export interface CostOverviewTotals {
	period: string;
	totalCost: number;
	totalCostPreviousTwelveMonths: number;
	totalAzureCostPreviousTwelveMonths: number;
	totalOffice365CostPreviousTwelveMonths: number;
	totalConsultancyCostPreviousTwelveMonths: number;
	totalYourEmployeesCostPreviousTwelveMonths: number;
	totalYourITSystemsCostPreviousTwelveMonths: number;
	totalHardwareCostPreviousTwelveMonths: number;
	totalCurrentCostThisMonth: number;
	totalCostPreviousMonth: number;
	azure: AzureCostShort[];
	office365: Office365CostShort[];
	consultancy: ConsultancyCostShort[];
	yourEmployees: YourEmployeesCostShort[];
	yourITSystems: YourITSystemsCostShort[];
	hardware: HardwareCostShort[];
}

export interface AllCostTypesShortApiObject {
	TenantId: string;
	CustomerName: string;
	Period: string;
	TotalCost: number;
	TotalCurrentCostThisMonth: number;
	TotalCostPreviousMonth: number;
	Azure: {
		Anomalies: Anomalies;
		Periods: AzureCostShort[];
	};
	Office365: Office365CostShort[];
	Consultancy: ConsultancyCostShort[];
	YourEmployees: YourEmployeesCostShort[];
	YourITSystems: YourITSystemsCostShort[];
	Hardware: HardwareCostShort[];
}

export interface AzureCostShort {
	period: string;
	totalForecasted: number;
	totalPreTaxAzure: number;
	totalForecastedAzure: number;
	totalForecastedMarketplaceItems: number;
	totalForecastedReservedInstances: number;
	anomalies: Anomalies;
}

export interface Office365CostShort {
	period: string;
	totalForecasted: number;
}

export interface ConsultancyCostShort {
	period: string;
	monthly_BilledCost: Price;
}

export interface YourITSystemsCostShort {
	period: string;
	totalPriceSum: number;
}

export interface YourEmployeesCostShort {
	period: string;
	totalPriceSum: number;
}

export interface HardwareCostShort {
	period: string;
	totalPriceSum: number;
}

export interface Invoice {
	customerName: string;
	customerNumber: string;
	country: string;
	referenceNumber: string;
	postPeriod: string;
	documentDate: string;
	documentDueDate: string;
	documentType: string;
	status: string;
	currencyId: string;
	amount: number;
	invoiceText: string;
	invoicePdf: string; // buffer of pdf
	attachments: Attachment[];
}

export interface Attachment {
	name: string;
	id: string;
	revision: number;
}

export interface SubscriptionShort {
	subscriptionName: string;
	totalCost: number;
	totalPreTax: number;
	totalForecasted: number;
}

export interface LicenseSubscriptionCostDetails {
	subscriptionName: string;
	subscriptionId: string;
	quantity: number;
	newQuantityThisPeriod: number;
	unitPricePreTax: number;
	discountAmountPreTax: number;
	discountPercentage: string;
	totalForecastedPreTax: number;
	usageDetails: UsageDetails;
	commitmentEndDate: string;
	billingCycle: string;
	termDuration: string;
	autoRenewEnabled: boolean;
}

export interface LicenseNameDTO {
	Product_Display_Name: string;
	String_Id: string;
	GUID: string;
	Service_Plan_Name: string;
	Service_Plan_Id: string;
	Service_Plans_Included_Friendly_Names: string;
}

export interface LicenseName {
	licenseDisplayName: string;
	skuPartNumber: string;
	skuId: string;
}

export interface UsageDetails {
	provisioningId: string;
	consumedQuantity: number;
	totalQuantity: number;
	usageRatio: number;
}

export interface Product {
	displayName: string;
	billableCount: number;
	unitPrice: number;
	totalProductCost: number;
}
export interface SecurityDashboardSettings {
	SignIns: SignIns;
	MonthlyCost: MonthlyCost;
	DeviceCompliancy: DeviceCompliancy;
	MFAStats: MFAStats;
}

export interface MFAStats {
	Title: string;
	MembersWithMFALabel: string;
	MembersWithoutMFALabel: string;
	GuestsWithMFALabel: string;
	GuestsWithoutMFALabel: string;
}

export interface DeviceCompliancy {
	title: string;
	compliantLabelText: string;
	nonCompliantLabelText: string;
	noStatusLabelText: string;
}

export interface MonthlyCost {
	title: string;
	dateFormat: string;
}

export interface SignIns {
	title: string;
	dateFormat: string;
	successLabelText: string;
	failedLabelText: string;
	interruptedLabelText: string;
}

export interface SignInLogEntry {
	id: string;
	createdDateTime: string;
	userDisplayName: string;
	userPrincipalName: string;
	userId: string;
	appDisplayName: string;
	ipAddress: string;
	errorCode: number;
	failureReason: string;
	location: Location;
}

export interface Location {
	city: string;
	state: string;
	countryOrRegion: string;
	geoCoordinates: GeoCoordinates;
}

export interface GeoCoordinates {
	altitude: number | null;
	latitude: number;
	longitude: number;
}

export interface DataGenerationStatuses {
	office365Cost: ActionLoadingStatus;
	azureCost: ActionLoadingStatus;
	azureReservedInstancesCost: ActionLoadingStatus;
	azureMarketplaceItemsCost: ActionLoadingStatus;
	aggregatedData: ActionLoadingStatus;
}

export interface DataGeneration {
	kickstartedDataGeneration: boolean;
	kickstartedAt?: Date;
	dataGenerationStatuses: DataGenerationStatuses;
}

export interface Api {
	REPORTS_API_ENDPOINTS: any;
	tenantId: string;
	OCP_APIM_SUBSCRIPTION_KEY: string;
}

export interface FetchParams {
	params?: {};
}

export interface Auth {
	instance: IPublicClientApplication;
	account: AccountInfo;
	authConfig: { [key: string]: any };
}

export interface TokenAuth extends Auth {
	scopes: string[];
}

export interface IdTokenClaims extends TokenClaims {
	wids: string[]; // Azure AD roles
}

export type ApiParams = {
	auth: Auth;
	body?: { [key: string]: any };
	meta?: { [key: string]: any };
	headers?: { [key: string]: string };
	responseType?: ResponseType;
} & { [id: string]: any };

export interface AuthConfig {
	[key: string]: any;
}

export interface MailDomain {
	name: string;
	isDefault: boolean;
}

export interface Department {
	name: string;
	numberOfEmployees: number;
}
export interface LicenseRecommendationObject {
	totalPriceForecasted: number;
	tenantId: string;
	date: string;
	licenses: LicenseSubscriptionRecommendation[];
	idleUsers_Details: IdleUsersDetails[];
	totalCostChange: number;
	totalCostChange_Yearly: number;
	currentTotalCost: number;
	currentTotalCost_Yearly: number;
	subscriptionsWithRecommendations: string[];
	subscriptionExpirationThreshold: number;
	actionGUID: string;
}

export interface LicenseSubscriptionRecommendation {
	subscriptionOfferName: string;
	subscriptionSkuID: string;
	current_Total: number;
	current_Unassigned: number;
	current_Idle: number;
	current_P1YCount: number;
	current_P1MCount: number;
	currentp1m_To_p1yRatio: string;
	current_Price: number;
	new_Total: number;
	new_P1YCount: number;
	new_P1MCount: number;
	monthlyUnitPrice: number;
	annualUnitPrice: number;
	monthlyEndDate: Date;
	annualEndDate: Date;
	newp1m_To_p1yRatio: string;
	new_Price: number;
	costChange: number;
	costOfFlexibility: number;
	description: string;
	idleUsers_Details: IdleUsersDetailsShort[];
	subscriptions: LicenseSubscription[];
	recommendation_Types: CostSavingsType[];
	removable_Licenses: number;
	maxRemoveableQuantity: number;
	maxRemoveableQuantityBasedOnScheduledActions: number;
	possible_convertible_P1M: number;
	numScheduledForRemoval: number;
}

export interface RefundableQuantity {
	quantity: number;
	allowedUntilDateTime: string;
}

export interface RecommendationAction {
	actionType: CostSavingsType;
	recommendation: LicenseSubscriptionRecommendation;
	recommendationChanges: any;
}

export interface IdleUsersDetailsShort {
	userPrincipalName: string;
	id: string;
}

export interface IdleUsersDetails extends IdleUsersDetailsShort {
	displayName: string;
	lastRegisteredSignIn?: Date;
	skusBackToPool: string[];
	skusToRemove: string[];
	deleted: boolean;
	accountEnabled: boolean;
	onPremisesSyncEnabled: boolean;
	privilegedRole: boolean;
}

export interface LicenseSubscription {
	id: string;
	quantity: number;
	priceForSubscription: number;
	unitPrice: number;
	unitPriceAfterDiscount: number;
	termDuration: "P1Y" | "P1M";
	commitmentEndDate: Date;
	changes: Changes | null;
	new_Subscription: boolean;
	priority: LicenseChangePriority;
	autoRenewEnabled: boolean;
	refundableQuantity: RefundableQuantity[];
	billingCycle: string;
}

export interface Changes {
	autoRenewal: null;
	priceForSubscription: number | null;
	quantity: number | null;
}

export interface LicenseChangePriority {
	add: number;
	remove: number;
}

export interface RemovalQuantity {
	removalQuantity: number;
	subscriptionId: string;
	provisioningId: string;
	quantity: number;
	termDuration: string;
	unitPriceAfterDiscount: number;
	commitmentEndDate: Date;
	autoRenewEnabled: boolean;
	refundableQuantity: RefundableQuantity[];
}

export interface GroupedActions {
	groupStatus: string;
	licenseActions: LicenseAction[];
	userRemovals: number;
	licenseRemovals: number;
	numScheduledActions: number;
	skuGUID: string;
	licenseDisplayName: string;
}

export interface LicenseAction {
	PartitionKey: string;
	RowKey: string; // Always overriden by Azure Table Storage
	TableTimestamp: string; // Always overriden by Azure Table Storage
	GroupActionId?: string; // Group action ID only present for actions conducted in group (i.e SoftDeleteUser which triggers a removal of license)
	ActionId: string;
	TenantId: string;
	CustomerName: string;
	Operation: LicenseActionOperationType;
	TargetType: "Subscription" | "License" | "User";
	TargetGUID: string;
	SkuGUID: string;
	TargetFriendlyName: string;
	QuantityChange: number;
	Status: LicenseActionStatus;
	StatusInfo: StatusInfo[];
	RecommendedAction: boolean;
	ExecutionDate: string;
	Description: string;
	OrderedBy: string;
	OrderedByDisplayName: string;
	OrderDate: string;
	TotalSavings: number;
	PurchaseInfo: PurchaseInfo;
	ConfirmationMailRecipient: string;
}

export interface LicenseActionEffect {
	lastModifiedAt: Date;
	autoRenewal: {
		modified: boolean;
		newValue: boolean;
		action: LicenseAction;
	};
	quantity: {
		modified: boolean;
		changeQuantity: number;
		actions: LicenseAction[]; // Only the last autoRenewal action is relevant
	};
}

export interface PurchaseInfo {
	TermDuration: string;
	ProductId: string;
	SkuId: string;
}

export interface StatusInfo {
	Status: LicenseActionStatus;
	Message: string;
	OrderedBy: string;
	ActivityDate: string;
}

export enum LicenseRemovalType {
	remove = "remove",
	backToPool = "backToPool",
}

export interface ConfiguredQuantityRemoval {
	subscriptionName: string;
	subscriptionSkuId: string;
	subscription: LicenseSubscription;
	removalQuantity: number;
	endDate: Date;
	savings: number;
	licenseRemovalType: LicenseRemovalType;
}

export interface LicenseGroup {
	groupID: string;
	groupName: string;
	memberCount: number;
	licenses: License[];
}

export interface AssignedLicense {
	skuId: string;
	disabledPlans: string[];
	servicePlans?: ServicePlan[];
	assignmentStatus: AssignmentStatus;
}

export interface AssignmentStatus {
	state: string;
	assignedByGroup: string;
	error: string;
	lastUpdatedDateTime: string;
}

export interface Group {
	groupType: string;
	name: string;
	id: string;
	description: string;
	isLicenseGroup: boolean;
}

export interface AccessGroup {
	displayName: string;
	prettyDisplayName: string;
	id: string;
	description: string;
	groupType: string;
	memberCount: number;
}

export interface ServicePlan {
	servicePlanId: string;
	servicePlanName: string;
	provisioningStatus: string;
	appliesTo: string;
}

export interface LicenseDetail {
	displayName: string;
	id: string;
	skuId: string;
	skuPartNumber: string;
	servicePlans: any[];
}

export interface NewEmployeeFormFields {
	givenName: string;
	surname: string;
	mailPrefix: string;
	mailSuffix: string;
	privateEmail: string;
	password: string;
	officeLocation: string;
	streetAddress: string;
	department: string;
	mobilePhone: string;
	manager: string;
	country: string;
	city: string;
	postalCode: string;
	licenseGroups: [];
	addUserToGroups: [];
	addUserToAccessGroups: AccessGroup[];
	chosenLicenses: License[];
	agreedToExtendMissingLicenses: boolean;
	hardwareNote: string;
	companyName: string;
	deliveryOption: DeliveryOption;
}

export type FormEmployee = Omit<NewEmployeeFormFields, "manager"> & { manager: User };

export type Employee = FormEmployee | NewEmployeeFormFields;

export interface User {
	id: string;
	userPrincipalName: string;
	givenName: string;
	displayName: string;
	surname: string;
	mail: string;
	city: string;
	label: string;
	type: string;
	jobTitle: string;
	department: string;
	postalCode: string;
	mobilePhone: string;
	streetAddress: string;
	officeLocation: string;
	businessPhones: string[];
	privateEmail: string;
	groups: Group[];
	userType: string;
	identities: UserIdentity[];
	credentialRegistrationDetails: CredentialRegistrationDetails;
	licenses: LicenseAssignedToUser[];
	accountEnabled: boolean;
	createdDateTime: string;
	signInActivityStatus: UserActivityType;
	country?: string;
	lastSignInDateTime?: string;
	manager?: string;
	devices?: DeviceShort[];
}

export interface CredentialRegistrationDetails {
	isMfaCapable: boolean;
	userType: string;
	id: string;
	isAdmin: boolean;
	methodsRegistered: UserAuthenticationMethod[];
}

export interface LicenseAssignedToUser {
	skuId: string;
	assignedByGroup: string;
	error: string;
	state: string;
	disabledPlans: string[];
	lastUpdatedDateTime: string;
}

export interface UserIdentity {
	issuer: string;
	issuerAssignedId: string;
}

export interface UserLicenseAssignmentState {
	userId: string;
	displayName: string;
	mail: string;
	otherAssignedLicenses: string[];
	licenseAssignmentErrors: {
		skuId: string;
		disabledPlans: any[];
		assignedByGroup: string | null;
		state: string;
		error: string | null;
		lastUpdatedDateTime: string;
		licenseDisplayName: string;
	}[];
}

export interface LicenseAssignmentTableRowData {
	userDisplayName: string;
	userMail: string;
	userId: string;
	licenseDisplayName?: string;
	skuId: string;
	disabledPlans: any[];
	assignedByGroup: string | null;
	state: string;
	error: string | null;
	lastUpdatedDateTime: string;
	otherAssignedLicenses: string[];
}

export interface LicensePurchase {
	quantity: number;
	skuId: string;
	variant: SubscriptionVariant;
	purchaseType:
		| LicenseActionOperationType.CreateNewSubscription
		| LicenseActionOperationType.AdjustLicenseCount;
	termDuration: TermDuration;
	costIdentifier: string;
	friendlyName: string;
}

export interface LicenseAssignmentAndPurchase {
	purchases: {
		[skuId: string]: LicensePurchase;
	};
	directAssignments: {
		[userId: string]: string[]; // userId: skuId[]
	};
	groupAssignments: {
		[groupId: string]: string[]; // groupId: skuId[]
	};
	skuInformation: {
		[skuId: string]: {
			assignmentQuantity: number;
			availableQuantity: number;
		};
	};
}

export interface LicenseAssignmentConflict {
	userId: string;
	skuIds: string[];
}

export interface LicenseAssignmentResponse {
	assignments: {
		directAssignments: DirectAssignmentChange[];
		groupAssignments: GroupAssignmentChange[];
	};
}

export interface LicenseRemovalResponse {
	directRemovals: DirectAssignmentChange[];
	groupRemovals: GroupAssignmentChange[];
}

export interface DirectAssignmentChange {
	userId: string;
	skuIds: string[];
	isError?: boolean;
}

export interface GroupAssignmentChange {
	userId: string;
	groupIds: string[];
	isError?: boolean;
}

export interface UsageLocation {
	id: string;
	label: string;
	numberOfEmployees: number;
}

export interface Requester {
	id: string;
	displayName: string;
	firstName: string;
	lastName: string;
	mail: string;
}

export interface Department {
	id: number;
	name: string;
	numberOfEmployees: number;
}

export interface CustomerCountry {
	countryCode: string;
	countryName: string;
	numberOfEmployees: number;
}

export interface DeviceShort {
	id: string;
	operatingSystem: string;
	operatingSystemVersion: string;
	displayName: string;
	lastActivityDate: string;
	registeredDate: string;
	isCompliant: boolean;
}
export interface DeviceOwnerInfo {
	id: string;
	displayName: string;
	userPrincipalName: string;
}

export interface Device {
	ownerUserId: string;
	deviceOwnerInfo?: DeviceOwnerInfo;
	deviceId: string;
	graphDeviceId: string;
	accountEnabled: boolean;
	createdDateTime: string;
	approximateLastSignInDateTime: string;
	deviceOwnership?: "Company" | "Personal";
	displayName: string;
	enrollmentType?: "AzureDomainJoined" | "UserEnrollment";
	complianceStatus: "Compliant" | "Not Compliant" | "Unmanaged";
	isCompliant: boolean;
	isManaged?: boolean;
	isIronstoneManaged?: boolean;
	ironstoneManagementType?: string;
	managementType?: "MDM" | "MAM";
	operatingSystem: string;
	operatingSystemShort: string;
	operatingSystemVersion: string;
	registrationDateTime: string;
	trustType?: "AzureAd" | "Workplace";
	complianceInfo?: DeviceComplianceInfo;
	// New properties used for filtering, maybe remove some old ones?
	entraStatus: "Registered" | "Joined";
	deviceStatuses: DeviceComplianceStatus[];
	intuneEnrollmentStatus: IntuneManagementType;
	serialNumber?: string; // Only for intune enrolled devices
}

export interface DeviceComplianceInfo {
	CalculatedPolicyVersion: number;
	DeviceId: string;
	DeviceName: string;
	ErrorCodeString: string;
	LastContact: string;
	LatestPolicyVersion: number;
	OS: string;
	PolicyName: string;
	SettingNm: string;
	SettingNm_loc: string;
	SettingStatus: number;
	SettingStatus_loc: string;
	UPN: string;
	UserId: string;
	ComplianceExplanation: ComplianceExplanation;
}

export interface ComplianceExplanation {
	ComplianceSetting: string;
	title: string;
	description: string;
}

export interface Customer {
	folderPath: string;
	internalCollaborationData: Data;
	externalCollaborationData: Data;
	internalProjectData: Data;
	externalProjectData: Data;
	workspaceSettings: WorkspaceSettings;
}

export interface Data {
	approvers: Approver[];
	creators: any[];
	enabled: boolean;
}

export interface CustomerDTO {
	creators: Creators;
	workspaceSettings: WorkspaceSettings;
	approvers: Approvers;
}

export interface Approvers {
	internalCollaborationApprovers: Approver[];
	externalCollaborationApprovers: Approver[];
	internalProjectApprovers: Approver[];
	externalProjectApprovers: Approver[];
}

export interface Approver {
	id: string;
	groupingType: string;
	displayName: string;
	type: string;
	userPrincipalName?: string;
	assignedLicenses?: AssignedLicense[];
	label?: string;
	mail?: string;
	groupType?: string;
	department?: string;
}

export interface AssignedLicense {
	skuID: string;
	disabledPlans: string[];
}

export interface Creators {
	externalProjectCreators: any[];
	internalProjectCreators: any[];
	internalCollaborationCreators: any[];
	externalCollaborationCreators: any[];
}

export interface WorkspaceSettings {
	externalProjectEnabled: boolean;
	externalCollaborationEnabled: boolean;
	internalCollaborationEnabled: boolean;
	internalProjectEnabled: boolean;
}

export interface CustomerInfo {
	isMocked: boolean; // true if the customer is a mock customer
	departmentId: number;
	parentCompany: string;
	departmentName: string;
	primeUserEmail: string;
	headUserEmail: string;
	primeUserId: string;
	headUserId: string;
	companyUrl: string;
	approvers: { id: string; displayName: string; mail: string }[];
	tam: string;
	commercialLead: string;
	confluence: string;
	hasPortalAccess: boolean;
	hasKomplettRelationship: boolean;
	isSetupForLeasing: boolean;
	primaryTenantId: string;
	komplettId: string;
	komplettIdLeasing: string;
	komplettIdSweden: string;
	shippingCountryAlternatives: ShippingCountryAlternative[];
	hasAppleBusinessOrganizationId: boolean;
	appleBusinessOrganizationId: string;
	yourEmployeesEnabled: boolean;
	yourEmployeesActivatedAzureAdTenant: string;
	yourItSystemsEnabled: boolean;
	yourItSystemsActivatedAzureAdTenantIds: string;
	yourItSystemsActivatedAzureSubscriptions: string;
	backup: boolean;
	deviceManagementEnabled: boolean;
	printEnabled: boolean;
	posKioskEnabled: boolean;
	employeeSupportEnabled: boolean;
	extendedEmployeeSupportEnabled: boolean;
	extendedResponseEnabled: boolean;
	deprecatedPeopleServiceEnabled: boolean;
	extendedSecurity: boolean;
	location?: string;
}

export interface CustomerLocation {
	city: string;
	address: string;
	postalCode: string;
	countryCode: string;
	country: string;
	name: string;
	title: string;
	email: string;
	phone: string;
}

export interface ShippingCountryAlternative {
	countryKomplettId: string;
	name: string;
}

export interface Branding {
	backgroundColor: string;
	backgroundImageRelativeUrl: string;
	bannerLogoRelativeUrl: string;
	squareLogoRelativeUrl: string;
	squareLogoDarkRelativeUrl: string;
	cdnList: string[];
	brandingBase64: string;
	logoBase64: string;
}

export interface AppRoleAssignment {
	roleId: string;
	roleDescription: string;
	roleDisplayName: string;
	roleValue: string;
	roleAssignedUsers: RoleAssignedUser[];
}

export interface RoleAssignedUser {
	userId: string;
	userDisplayName: string;
}

export interface HardwareApproverObject {
	global: HardwareApprover[];
	manager: HardwareApprover[];
}

export interface HardwareApprover {
	id: string;
	displayName: string;
	mail: string;
	phone: string;
	approverType: ApproverType;
}

// Approvers for e.g tickets in fresh and other support stuff
export interface CompanyApprover {
	id: string;
	displayName: string;
	mail: string;
}

export interface ManagerStructure {
	[key: string]: ManagerDetails;
	usersWithNoManager: any; // Really a string array but typescript wont let me :'()
}

export interface ManagerDetails {
	managerOf: string[];
	direct: number;
	indirect: number;
}

export interface ApiConfig {
	api: Api;
}

export interface Api {
	tenantId: string;
	OCP_APIM_SUBSCRIPTION_KEY: string;
}

export interface LicenseGroup {
	groupID: string;
	groupName: string;
	licenses: License[];
}

export interface LicensePriceDTO {
	TenantId: string;
	CustomerName: string;
	LicensePrices: LicensePrice[];
}

export interface LicensePrice {
	ProductName: string;
	ProductSkuId: string;
	SubscriptionId: string;
	UniqueProviderOfferId: string;
	BillingCycleName: string;
	TermDuration: string;
	CustomerDiscountInPercent: number;
	CurrencyCode: string;
	UnitPrice: number;
	UnitPriceAfterDiscount: number;
	PriceLocked: boolean;
	PriceLockedUntil: string;
	CostPeriod: string;
}

export interface License {
	name: string;
	groupName: string;
	displayName: string;
	usedLicenses: number;
	unusedLicenses: number;
	skuId: string;
	memberOfGroup: string;
	subscriptionVariants: SubscriptionVariant[];
	chosenVariant: SubscriptionVariant;
	needsProvisioning: boolean;
	isAlreadyAssigned?: boolean;
}

export interface PreviewCostData {
	monthlyCost: number;
	monthlySavings: number;
	monthlyCostAfterSavings: number;
	yearlySavings: number;
}

export interface SubscribedSku {
	// A sku (license) that is subscribed through the creation of one or more LicenseSubscriptions
	id: string;
	skuId: string;
	subscriptionIds: string[];
	capabilityStatus: CapabilityStatus;
	consumedUnits: number;
	appliesTo: LicenseAppliesTo;
	prepaidUnits: {
		enabled: number;
		suspended: number;
		warning: number;
		lockedOut: number;
	};
}

// Definition: SubscriptionVariant is a variant of a subscription with the same name (e.g "Office 365 E3"),
// But with different term duration
export interface SubscriptionVariant {
	friendlyName: string;
	subscriptionId: string;
	quantity: number;
	provisioningId: string;
	billingCycle: string;
	termDuration: string;
	offerId: string;
	costIdentifier: string;
	commitmentEndDate: Date;
	creationDate: Date;
	autoRenewEnabled: boolean;
	orderId: string;
	cost: {
		cost: number;
		currency: string;
		description: string;
	};
	refundableQuantity?: RefundableQuantity[];
	purchaseQuantity: number;
}

export type Loader = {
	label: string;
	response: string;
	pending: string;
	error?: string;
	suggestion?: any;
	status: OnboardingStatus;
};

export type OnboardingEntity = "user" | "licenses" | "order" | "mail" | "sms";

export type Loaders = { [name in OnboardingEntity]: Loader };

export interface SubscriptionVariantGroup {
	friendlyName: string;
	provisioningId: string;
	costIdentifiers: {
		[TermDuration.P1M]: string;
		[TermDuration.P1Y]: string;
	};
	preferredVariantId: string;
	variants: SubscriptionVariant[];
}

export interface Order {
	orderedBy: string;
	orderedFor: string;
	isOrderCompleted: boolean;
	hardwareNote?: string;
	orderNumber: number;
	komplettOrderNumber: number;
	orderIssueDate: string;
	statusDetails: StatusDetails;
	orderedItems: {
		productId: string;
		displayName: string;
		price: number;
		quantity: number;
		link: string;
	}[];
	trackingLinks?: TrackingLink[];
	shippedDate?: string;
	transportationMethod: string;
	invoicedDate: string;
	totalInvoiceCost: number;
	totalInvoiceCostPreTax: number;
	invoiceCurrency: string;
	invoicedItems: {}[];
}

export interface ProductsMetadata {
	manufacturers: { [key: string]: number };
	categories: { [key: string]: number };
}

export type ChartBarLabelProp =
	| "totalPreTax"
	| "restOfMonthForecast"
	| "lastMonthTotalPreTax"
	| "restOfLastMonthTotal";

export type ChartBarProp =
	| "subscriptionName"
	| "resourceGroupName"
	| "resourceName"
	| "tagCombination";

export type ChartBarLabelByProperty = {
	[key in ChartBarLabelProp]: string;
};

export type ChartBarCount = {
	[key in ChartBarLabelProp]: number;
};

export type ChartBarItem = {
	[key in ChartBarProp]: string;
};

export type ChartBar = ChartBarCount & { y: string };

export type ChartData = (ChartBarCount &
	ChartBarItem & {
		monthlyForecastDifference: number;
		lastMonthCompleteMonthTotalDifference: number;
	})[];

export type HardwareGroup =
	| "PC_NOTEBOOK_BUSINESS"
	| "PHONES_MOBILE"
	| "PC_ACCESSORIES"
	| "MICE_KEYBOARDS"
	| "SOUND"
	| "MONITOR"
	| "APPLE_PC_NOTEBOOK";

export type HardwareGroups = {
	[key in HardwareGroup]: boolean;
};

export type Manufacturer = "Apple" | "Microsoft" | "Lenovo" | "HP" | "Samsung" | "Logitech";

export type Manufacturers = {
	[key in Manufacturer]: boolean;
};

export type HardwareProductExtended = HardwareProduct & { searchTerms: string[] };

// Api response types
export interface ApiResponse<T = unknown> {
	message: string;
	data: T;
}

export interface InvoiceDownloadStatus {
	[referenceNumber: string]: DownloadFileStatusEnum;
}

export interface DocumentDownloadStatus {
	[attachmentId: string]: DownloadFileStatusEnum;
}

export interface Document {
	id: number;
	title: string;
	fileSize: number;
	fileType: string;
	comment: string;
	spaceKey: string;
	pageId: number;
	downloadLink: string;
	lastModified: string;
	pageName: DocumentType;
}

export interface DocumentResponse {
	message: string;
	value: Document[];
}

export interface LicenseAutoCompleteType {
	id: string;
	name: string;
	type: string; // Todo: enum
	assignedBy?: string;
}

export interface LoadingDialogStep {
	title: string;
	status: ActionLoadingStatus;
}

export interface DeviceFilters {
	[DeviceFilterKey.OS]: string[];
	[DeviceFilterKey.INTUNE]: IntuneManagementType[];
	[DeviceFilterKey.COMPLIANCE]: string[];
}

export interface AuthenticationStrengthGroup {
	name: string;
	strength: string;
	methods: UserAuthenticationMethod[];
}

export interface RunSettings {
	Method?: string;
	Uri?: string;
	FunctionKey_KeyVaultName?: string;
	FunctionKey_KeyVaultSecretName?: string;
	Body?: {
		ReportTypes?: string[];
		DailyLogs?: boolean;
		CentralDBData?: boolean;
		IncludePersonalData?: boolean;
	};
	RunOn?: string;
	RunbookName?: string;
	ResourceName?: string;
	ResourceGroupName?: string;
	Parameters?: {
		Periods?: number;
	};
}

export interface ReportCreationTask {
	Name: string;
	DisplayName: string;
	ShowInPortal: boolean;
	RunSettings: RunSettings;
	Status: ReportCreationStatus;
	Type: string;
	Error: string;
	SummarySuccessMatch: string;
	DependsOn: string[];
}

export interface SharePointStorageDataResponse {
	companyId: string;
	CompanyName: string;
	Date: string;
	Check: string;
	CheckValues: SharePointCheckValues[];
}

export interface SharePointCheckValues {
	tenantStorageQuotaInMB: number;
	tenantUsageDataInMB: number;
	percentOfStorageUsed: number;
	sites: SharePointSite[];
}

export interface SharePointSite {
	Title: string | null;
	URL: string;
	Description: string | null;
	Owner: string | null;
	StorageQuota: number;
	StorageMaximumLevel: number | null;
	StorageUsageCurrent: number;
	ResourceQuota: number;
	ResourceQuotaWarning: number;
	ResourceUsageAverage: number;
	ResourceUsageCurrent: number;
	Template: string;
	SharingCapability: number;
	LockStatus: string;
	LastModifiedDate: string;
	SubsitesCount: number;
}

export interface ExchangeMailboxDataResponse {
	companyId: string;
	CompanyName: string;
	Date: string;
	Check: string;
	CheckValues: ExchangeMailboxData[];
}

export interface ExchangeMailboxData {
	UserPrincipalName: string;
	Alias: string;
	DisplayName: string;
	EmailAddresses: string[];
	GrantSendOnBehalfTo: string[]; // or another type if needed
	HiddenFromAddressListsEnabled: boolean;
	RecipientTypeDetails: string;
	ArchiveState: string; // e.g. "None"
	TotalItemSizeInKB: number;
	IssueWarningQuotaInKB: number;
	ProhibitSendQuotaInKB: number;
	ProhibitSendReceiveQuotaInKB: number;
	ForwardingSmtpAddress: string | null;
	ForwardingAddress: string | null;
	Guid: string;
}

export interface SecureScoreSummary {
	currentScore: number;
	currentPercentageScore: number;
	currentMaxScore: number;
	previousMonthScore: number;
	oneMonthAgoMaxScore: number;
	previousPeriodPercentageScore: number;
	controlScoresSummed: number;
	controlScoresPreviousPeriod: number;
	percentageChange: number;
}
