const Print = ({ active }: { active: boolean }) => (
	<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<ellipse
			cx="19.7842"
			cy="18.546"
			rx="19.5576"
			ry="18.546"
			fill={active ? "#FFDD7C" : "#EEEEEE"}
		/>
		<path
			transform="translate(7, 7)"
			d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"
			fill={active ? "#FFDD7C" : "#a3a3a3"}
		/>
	</svg>
);

export default Print;
