import { Grid, ListItem, Typography } from "@mui/material";
import dayjs from "dayjs";
import { CUSTOMER_FACING_STATUS } from "utilities/constants/constants";
import styles from "./ContentItem.module.scss";
import { ColorIndicator } from "../../../../ColorIndicator";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import { DATE_FORMATS } from "utilities/constants/enums";

interface IncidentItemProps {
	id: number;
	subject: string;
	customer_facing_status: string;
	created_at: string;
	updated_at?: string;
	isPremium: boolean;
	onCloseAlertClick: (id: number) => void;
	onEscalateClick: (id: number) => void;
}

const ContentItem = ({
	id,
	subject,
	customer_facing_status,
	created_at,
	updated_at,
	isPremium,
	onEscalateClick,
	onCloseAlertClick,
}: IncidentItemProps): JSX.Element => {
	return (
		<ListItem divider={true} classes={{ root: styles.listItem }}>
			<Grid container classes={{ root: styles.container }}>
				<Grid item xs={12} classes={{ root: styles.incidentDateContainer }}>
					{customer_facing_status === CUSTOMER_FACING_STATUS.RESOLVED.id &&
						!!updated_at && (
							<Typography
								classes={{ root: styles.subtitle }}
								variant="caption"
								component="p"
							>{`Incident resolved - ${dayjs(updated_at).format(
								DATE_FORMATS.FULL,
							)}`}</Typography>
						)}
					{customer_facing_status === CUSTOMER_FACING_STATUS.BEING_PROCESSED.id && (
						<Typography
							classes={{ root: styles.subtitle }}
							variant="caption"
							component="p"
						>{`Ironstone is handling incident - ${dayjs(created_at).format(
							DATE_FORMATS.FULL,
						)}`}</Typography>
					)}
				</Grid>
				<Grid container item xs={11.5} className={styles.subjectContainer}>
					<ColorIndicator status={customer_facing_status} />
					<Typography variant="body1">{subject}</Typography>
				</Grid>
				<Grid item xs={0.5} />
				<Grid item xs={10} classes={{ root: styles.buttonsContainer }}>
					{customer_facing_status === CUSTOMER_FACING_STATUS.AWAITING_REPLY.id && (
						<Grid container>
							<PrimaryButton
								text="Escalate to Ironstone"
								size="small"
								variantStyle="contained"
								action={() => {
									onEscalateClick(id);
								}}
								marginRight={3}
							/>
							{isPremium && (
								<SecondaryButton
									text={"Close alert"}
									size="small"
									variantStyle="outlined"
									action={() => {
										onCloseAlertClick(id);
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</ListItem>
	);
};

export { ContentItem };
