export const TERMS_AND_CONDITIONS = {
	DISCLAIMER:
		"By approving this confirmation on your license purchase, you accept the following terms and conditions:",
	FIRST_SECTION: [
		"1) The selected billing term and frequency (If not stated otherwise, the billing frequency is on a monthly basis)",
		"2) Any cancellation and seat-count policies",
		"3) Ironstone does not take responsibility if license/licenses were mistakenly purchased",
		"4) The applicable terms for the products you selected",
		"5) After the purchase is complete, you have 7 days for any cancellations.",
	],
	P1Y_TERM_CONDITIONS:
		"Annual subscription commitment will be billed monthly, but license are committed yearly. 20 % cost reduction compared to monthly commitment. Quantity can only be increased (not decreased) during the subscription duration period. Upgrade within the suite is possible, for instance moving from Business Standard to Premium or E3 to E5.",
	P1M_TERM_CONDITIONS:
		"Monthly subscription commitment will be billed monthly. A flexible term, were you are able to add or remove licenses on a monthly basis, with a cost premium of 20% more than a annual commitment. ",
};

export const PRODUCT_DESCRIPTIONS = {
	YOUR_EMPLOYEES: {
		PRODUCT_1: "Standard package",
		PRODUCT_3: "Office 365 backup",
		PRODUCT_5: "Cloud print solution",
		PRODUCT_6:
			"Device management using Intune for point of sale or kiosk devices is delivered to the customer",
		PRODUCT_7: "First line support between 8AM and 5PM, Monday to Friday",
		PRODUCT_8: "First line support between 5PM and 10PM, Monday to Friday",
		PRODUCT_9: "Support for up to 5 admins 17:00 - 22:00,  Monday to Friday",
		PRODUCT_10:
			"Detect and Response package using Defender products and Sentinel, Monday to Friday",
	},
	YOUR_IT_SYSTEMS: {
		PRODUCT_50: "Standard package",
		PRODUCT_51:
			"Incident escalation, advanced threat protection, minimized data and availability loss, 24/7 response time",
		PRODUCT_52:
			"Receive proactive alerts, access continuous third-party status, streamline troubleshooting",
		PRODUCT_53: "Prioritized, discounted extended response time, 17-22",
		PRODUCT_54: "Prioritized, discounted extended response time, 24/7",
	},
} as { [key: string]: { [key: string]: string } };

export const noAccessText = "You do not have access to this page.";

export const LICENSE_ERRORS_EXPLANATION = {
	MutuallyExclusiveViolation: {
		description:
			"This license is mutually exclusive with another license - you cannot assign both licenses to the same user.",
		displayName: "Conflicting license assignments",
	},
	CountViolation: {
		description:
			"You have exceeded the number of licenses you can assign, please either remove a license from another user or purchase more licenses.",
		displayName: "Not enough licenses",
	},
	DependencyViolation: {
		description: "This license cannot be removed, because other products depend on it.",
		displayName: "License dependency",
	},
	ProhibitedInCountry: {
		description:
			"The license cannot be assigned in the user's location. Some licenses have geographic restrictions and cannot be assigned to users in certain countries or regions.",
		displayName: "Usage location not allowed",
	},
	InvalidLicense: {
		description:
			"The license is not recognized or is invalid, this can occur if the license ID is incorrect, the license does not exist, or it is not available in the tenant’s subscription.",
		displayName: "Invalid or unrecognized license",
	},
	LicenseAssignmentDisabled: {
		description: "License assignment for this user is disabled",
		displayName: "License Assignment Blocked",
	},
	ConflictingServicePlans: {
		description:
			"The license cannot be assigned because it contains conflicting service plans, this occurs when trying to assign a license that contains service plans that conflict with service plans of already assigned licenses.",
		displayName: "Conflicting service plans",
	},
	ServicePlanInactive: {
		description:
			"One or more service plans within the license are inactive. This error means that the license contains one or more service plans that are not currently active for assignment, possibly due to subscription changes or regional availability.",
		displayName: "Inactive Service Plan",
	},
};

export const ROLE_DESCRIPTIONS = {
	ADMIN: "Access to all pages with all functionality.",
	PORTALREAD: "Access to all pages with read-only functionality.",
	FINANCE: "Access to all cost pages with read-only functionality.",
	HR: "Manage employees, Onboarding / Offboarding, Buy Hardware, Order History, License Management",
};
