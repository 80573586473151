import { CustomerInfo, RolesAccess } from "types";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

export const getDefaultPage = (
	loggedInUserRoles: string[],
	roleAccessDefaults: RolesAccess,
	customerInfo: CustomerInfo,
) => {
	if (!loggedInUserRoles || loggedInUserRoles.length === 0 || !loggedInUserRoles[0]) {
		// No roles (or no valid roles) => redirect to the "My profile" page (should not happen, but just in case)
		return roleAccessDefaults.USER.DEFAULT_PAGE;
	}

	// If the customer has not bought any of the products, we redirect to the "Security-dashboard"
	if (!customerInfo?.yourEmployeesEnabled && !customerInfo?.yourItSystemsEnabled) {
		return NAVIGATION_NAMES.COST_MANAGEMENT;
	}

	return basedOnRole(loggedInUserRoles, roleAccessDefaults);
};

const basedOnRole = (loggedInUserRoles: string[], roleAccessDefaults: RolesAccess) => {
	let defaultPage = roleAccessDefaults.USER.DEFAULT_PAGE; // Default page if no role is found

	// Find the first valid role
	for (const role of loggedInUserRoles) {
		if (roleAccessDefaults[role.toUpperCase() as keyof RolesAccess]) {
			defaultPage = roleAccessDefaults[role.toUpperCase() as keyof RolesAccess].DEFAULT_PAGE;
			break;
		}
	}

	return defaultPage;
};
