import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import {
	selectActiveLicenseCompleteDetails,
	selectLicensedUsersDialogState,
	setLicensedUsersDialogState,
} from "features/licenses/licenses";
import { usersSelectors } from "features/users";
import { useAppSelector, useAuth } from "hooks/hooks";
import { useMemo, useState } from "react";
import { getIcon } from "utilities/microsoftIconsUtils";
import CloseIcon from "@mui/icons-material/Close";
import { setActiveTab, setUserDrawerOpen } from "features/user";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import { licenseNameSelectors } from "features/licenses/licenseNames";
import { LicenseAssignmentState } from "utilities/constants/enums";
import { HoverTooltip } from "components/Common/Tooltips";
import { InputTextField } from "components/Common/InputTextField";

import styles from "./LicensedUsersDrawer.module.scss";

export const LicensedUsersDrawer = () => {
	const { dispatch } = useAuth();

	const { isOpen, licenseId } = useAppSelector(selectLicensedUsersDialogState);
	const users = useAppSelector(usersSelectors.selectAll);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const [searchValue, setSearchValue] = useState("");

	const handleClose = () => {
		dispatch(setLicensedUsersDialogState({ isOpen: false, licenseId: "" }));
	};

	const handleOpenUserDrawer = (userId: string) => {
		dispatch(setUserDrawerOpen({ iserDrawerOpen: true, activeUserId: userId }));
		dispatch(setActiveTab(1));
		dispatch(setLicensedUsersDialogState({ isOpen: false, licenseId: "" }));
	};

	const { usersWithLicense, usersWithAssignmentError } = useMemo(() => {
		return users.reduce(
			(acc, user) => {
				let filteredLicenses = [];
				let hasActiveLicense = false;
				let hasErrorLicense = false;

				for (const license of user.licenses) {
					if (license.skuId === licenseId) {
						if (license.state === LicenseAssignmentState.Active) {
							hasActiveLicense = true;
						} else if (license.state === LicenseAssignmentState.Error) {
							hasErrorLicense = true;
						}
					} else {
						filteredLicenses.push({
							skuId: license.skuId,
							state: license.state,
							name: licenseNames[license.skuId]?.licenseDisplayName ?? license.skuId,
						});
					}
				}

				if (hasActiveLicense) {
					acc.usersWithLicense.push({ ...user, licenses: filteredLicenses });
				}

				if (hasErrorLicense) {
					acc.usersWithAssignmentError.push({ ...user, licenses: filteredLicenses });
				}

				return acc;
			},
			{
				usersWithLicense: [] as {
					id: string;
					displayName: string;
					licenses: { skuId: string; state: string; name: string }[];
				}[],
				usersWithAssignmentError: [] as {
					id: string;
					displayName: string;
					licenses: { skuId: string; state: string; name: string }[];
				}[],
			},
		);
	}, [users, licenseNames, licenseId]);

	// Filter users based on search value
	const filteredUsersWithLicense = usersWithLicense.filter((user) =>
		user.displayName.toLowerCase().includes(searchValue.toLowerCase()),
	);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={handleClose}
			PaperProps={{ className: styles.drawer }}
			className={styles.licensedUsersDrawerWrapper}
			variant="temporary"
			aria-labelledby="licensed-users-drawer"
			hideBackdrop={false}
		>
			<Grid container className={styles.headerAndSearchContainer}>
				<Grid item className={styles.closeButtonContainer}>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Grid container className={styles.header}>
					<Grid item>
						<Grid container className={styles.titleContainer}>
							<svg width="20" height="20" viewBox="0 0 60 60">
								{getIcon({
									iconName: completeLicenseDetails?.friendlyName as string,
								})}
							</svg>
							<Typography variant="h2">
								{`Users with ${completeLicenseDetails?.friendlyName || ""}`}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid container className={styles.searchContainer}>
					<InputTextField
						noLabel
						fullWidth
						placeholder={`Search users with ${completeLicenseDetails?.friendlyName}`}
						InputLabelProps={{ shrink: true }}
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				</Grid>
			</Grid>
			<Grid container className={styles.errorUsers}>
				{usersWithAssignmentError.length > 0 && (
					<HoverTooltip
						title="Users with assignment errors"
						description={usersWithAssignmentError
							.map((user) => user.displayName)
							.join(", ")}
					>
						<Typography variant="body1" fontWeight={500}>
							{usersWithAssignmentError.length} users with assignment errors
						</Typography>
					</HoverTooltip>
				)}
			</Grid>
			<Grid container className={styles.content}>
				<Grid container className={styles.tableHeader}>
					<Grid container className={styles.leftColumn}>
						<Typography variant="body1" fontWeight={500}>
							Name
						</Typography>
					</Grid>
					<Grid container className={styles.rightColumn}>
						<Typography variant="body1" fontWeight={500}>
							Assigned licenses
						</Typography>
					</Grid>
				</Grid>
				<Grid container className={styles.tableContent}>
					{filteredUsersWithLicense.map((user) => (
						<Grid container key={user.id} className={styles.tableRow}>
							<Grid
								container
								className={styles.leftColumn}
								onClick={() => handleOpenUserDrawer(user.id)}
							>
								<UserInfoDisplay
									id={user.id}
									maxCharLength={{ mail: 35, name: 35 }}
								/>
							</Grid>
							<Grid container className={styles.rightColumn}>
								<Typography
									variant="body1"
									fontWeight={500}
									className={styles.mainLicensename}
								>
									{completeLicenseDetails?.friendlyName}
								</Typography>
								<Typography variant="caption">
									{user.licenses.map((license) => license.name).join(", ")}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Drawer>
	);
};
