import { selectTicketStatisticsByMonth } from "features/tickets/ticketStatistics";
import { useAppSelector, useAuth } from "hooks/hooks";
import dayjs from "dayjs";
import { useEffect, useMemo, useState, Fragment } from "react";
import { fetchTicketStatistics } from "actions/securityIncidentActions";
import { Grid, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { Ticket } from "types";

import styles from "./SupportTicketsDrawerContent.module.scss";
import { openInNewTab } from "utilities/openLink";
import { IRONSTONE_SUPPORT_URL } from "utilities/constants/constants";
import { OpenInNew } from "@mui/icons-material";
import { TruncatableTypography } from "components/Common/TruncateableTypography";

export const SupportTicketsDrawerContent = () => {
	const [isLoadingTickets, setIsLoadingTickets] = useState(false);
	const { dispatch, auth } = useAuth();

	const last12Months = useMemo(() => {
		const months = [];
		for (let i = 0; i < 4; i++) {
			months.push(dayjs().subtract(i, "month").format("MMMM YYYY"));
		}
		return months.reverse();
	}, []);

	const [selectedMonth, setSelectedMonth] = useState<string>(
		last12Months[last12Months.length - 1],
	);

	const ticketStatistics = useAppSelector(selectTicketStatisticsByMonth(selectedMonth));

	useEffect(() => {
		const from = dayjs(selectedMonth, "MMMM").startOf("month").format("YYYY-MM-DD");
		const to = dayjs(selectedMonth, "MMMM").endOf("month").format("YYYY-MM-DD");

		const fetchTickets = async () => {
			setIsLoadingTickets(true);
			await dispatch(fetchTicketStatistics({ auth, from, to }));
			setIsLoadingTickets(false);
		};

		// If no period is found for the selected month, fetch the data
		if (!ticketStatistics.period) {
			fetchTickets();
		}
	}, [selectedMonth, dispatch, auth, ticketStatistics.period]);

	return (
		<Grid container p={3} className={styles.container}>
			<Grid container className={styles.monthLabelsSelector}>
				{last12Months.map((month) => (
					<Typography
						key={month}
						className={clsx(styles.monthLabel, {
							[styles.selectedMonth]: month === selectedMonth,
						})}
						onClick={() => setSelectedMonth(month)}
					>
						{dayjs(month, "MMMM YYYY").format("MMMM")}
					</Typography>
				))}
			</Grid>
			{!isLoadingTickets ? (
				Object.entries(ticketStatistics?.tickets).map(
					([key, value]: [string, Ticket[]]) => (
						<Fragment key={key}>
							<Typography variant="h3" mt={3}>
								{key.charAt(0).toUpperCase() +
									key.slice(1).replace(/([A-Z])/g, " $1")}
							</Typography>
							<Grid container className={styles.scrollContainer}>
								<Grid item xs={12} container className={styles.ticketList}>
									{value.length === 0 ? (
										<Typography variant="description" mt={2}>
											No tickets found for
										</Typography>
									) : (
										value.map((ticket: Ticket) => (
											<TicketRow key={ticket.id} {...ticket} />
										))
									)}
								</Grid>
							</Grid>
						</Fragment>
					),
				)
			) : (
				<Grid container className={styles.skeletonContainer}>
					<Skeleton variant="rectangular" className={styles.loadingSkeleton} />
				</Grid>
			)}
		</Grid>
	);
};

interface TicketInputProps extends Ticket {}

const TicketRow = ({ id, subject, sub_category, created_at }: TicketInputProps) => {
	return (
		<Grid
			container
			className={styles.ticket}
			onClick={() => openInNewTab(`${IRONSTONE_SUPPORT_URL}a/tickets/${id}`)}
		>
			<Grid container item className={styles.ticketContent}>
				<Grid container gap={2}>
					<Typography variant="description" className={styles.description}>
						{id}
					</Typography>
					<Typography variant="description" className={styles.description}>
						{dayjs(created_at).format("DD. MMM HH:mm")}
					</Typography>
					<Typography variant="description" className={styles.description}>
						{sub_category}
					</Typography>
				</Grid>
				<TruncatableTypography variant="body1" maxCharLength={50}>
					{subject}
				</TruncatableTypography>
				<OpenInNew className={styles.openInNewIcon} />
			</Grid>
		</Grid>
	);
};
