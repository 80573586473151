import { createAsyncThunk } from "@reduxjs/toolkit";
import { SecureScoreSummary } from "types";
import { apiGet } from "utilities/api";
import { SCOPES } from "utilities/constants/constants";

export const fetchSecureScore = createAsyncThunk(
	"secureScore/fetch",
	apiGet<SecureScoreSummary>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_SECURE_SCORE(),
		[SCOPES.GRAPH_READ],
	),
);
