import { Grid } from "@mui/material";

import { CostTypeCard } from "./CostTypeCard";

import { ReactComponent as YourItSystems } from "assets/cost/yourItSystems.svg";
import { ReactComponent as MyEmployees } from "assets/cost/myemployees.svg";
import { ReactComponent as AzureCost } from "assets/cost/azure_cost.svg";
import { LicenseIcon } from "assets/cost/license_icon";
import { ReactComponent as Consultancy } from "assets/cost/consultancy.svg";
import { ReactComponent as HardwareIcon } from "assets/cost/hardware.svg";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { selectAnomalies, selectTotalCost, selectTotalCostObject } from "features/costs";

import AttachMoney from "@mui/icons-material/AttachMoney";
import NotificationImportantOutlined from "@mui/icons-material/NotificationImportantOutlined";

import colors from "styles/utilities/_colors.scss";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

import styles from "./TotalsCards.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import { fetchAllCosts } from "actions/costActions";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";

const TotalsCards = (): JSX.Element => {
	const { isLoading } = useAppSelector(selectTotalCost);

	const totalCostObjectCurrentMonth = useAppSelector(selectTotalCostObject);

	const { anomaliesData } = useAppSelector(selectAnomalies);

	useApiOnce(fetchAllCosts, useAppSelector(selectTotalCost));

	return (
		<div className={styles.costCardsWrapper}>
			<Grid container spacing={3} item xs={4} className={styles.largeCostTypeCardWrapper}>
				<Grid item xs={10} className={styles.largeCard}>
					<CostTypeCard
						isLoading={isLoading}
						title={"Total predicted this period"}
						totalCurrentPeriod={totalCostObjectCurrentMonth.totalCost}
						totalPreviousPeriod={totalCostObjectCurrentMonth.totalCostPreviousMonth}
						icon={<AttachMoney className={styles.totalsIcon} />}
						backgroundColor={colors.primary}
						size="medium"
						periodLabel={`${dayjs().startOf("month").format("DD.MM")} - ${dayjs()
							.endOf("month")
							.format("DD.MM")}`}
						iconBorder
					/>
				</Grid>
				<Grid item xs={10} className={styles.largeCard}>
					<CostTypeCard
						isLoading={isLoading}
						title={"Cost anomalies"}
						totalCurrentPeriod={
							_.size(anomaliesData?.resources) + _.size(anomaliesData?.subscriptions)
						}
						anomalies={anomaliesData}
						totalPreviousPeriod={totalCostObjectCurrentMonth.totalCostPreviousMonth}
						icon={<NotificationImportantOutlined className={styles.totalsIcon} />}
						backgroundColor={colors.primary}
						size="medium"
						type="anomalies"
						periodLabel={`${dayjs()
							.startOf("month")
							.format("DD.MM")} - ${dayjs().format("DD.MM")}`}
						iconBorder
					/>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={3}
				item
				xs={8}
				md={8}
				lg={8}
				xxl={6}
				className={styles.smallCostTypeCardWrapper}
			>
				{[
					{
						title: "Azure",
						totalCurrentPeriod: totalCostObjectCurrentMonth.azure.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.azure.previous,
						icon: <AzureCost />,
						backgroundColor: colors.azure,
						navigationPath: NAVIGATION_NAMES.AZURE_COST_DETAILED.path,
						isLoading: isLoading,
					},
					{
						title: IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES,
						totalCurrentPeriod: totalCostObjectCurrentMonth.yourEmployees.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.yourEmployees.previous,
						icon: <MyEmployees />,
						backgroundColor: colors.yourEmployees,
						navigationPath: NAVIGATION_NAMES.IT_FOR_YOUR_EMPLOYEES_COST.path,
						isLoading: isLoading,
					},
					{
						title: "Consultancy",
						totalCurrentPeriod: totalCostObjectCurrentMonth.consultancy.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.consultancy.previous,
						icon: <Consultancy />,
						backgroundColor: colors.consultancy,
						navigationPath: NAVIGATION_NAMES.CONSULTANCY_COST.path,
						isLoading: isLoading,
					},
					{
						title: "Licenses",
						totalCurrentPeriod: totalCostObjectCurrentMonth.office365.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.office365.previous,
						icon: <LicenseIcon />,
						backgroundColor: colors.license,
						navigationPath: NAVIGATION_NAMES.LICENSE_COST_DETAILED.path,
						isLoading: isLoading,
					},
					{
						title: IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS,
						totalCurrentPeriod: totalCostObjectCurrentMonth.yourITSystems.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.yourITSystems.previous,
						icon: <YourItSystems />,
						backgroundColor: colors.yourItSystems,
						navigationPath: NAVIGATION_NAMES.YOUR_IT_SYSTEMS_COST.path,
						isLoading: isLoading,
					},
					{
						title: "Hardware expenses",
						totalCurrentPeriod: totalCostObjectCurrentMonth.hardware.current,
						totalPreviousPeriod: totalCostObjectCurrentMonth.hardware.previous,
						icon: <HardwareIcon />,
						backgroundColor: colors.hardware,
						navigationPath: NAVIGATION_NAMES.ORDERS.path,
						navigationTitle: "Order history",
						isLoading: isLoading,
					},
				].map((props) => (
					<Grid key={props.title} item xs={6} md={4}>
						<CostTypeCard {...props} />
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export { TotalsCards };
