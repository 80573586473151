import { createSlice } from "@reduxjs/toolkit";
import { fetchTicketStatistics } from "actions/securityIncidentActions";
import { RootState } from "store";
import { Ticket, TicketStatistics } from "types";

interface TicketStatisticsByMonth {
	[month: string]: TicketStatistics;
}

interface TicketStatisticsState {
	isLoading: boolean;
	isFetching: boolean;
	/**
	 * A dictionary keyed by month, e.g. "2023-01": TicketStatistics
	 */
	ticketStatisticsByMonth: TicketStatisticsByMonth;
}

const ticketsSlice = createSlice({
	name: "ticketStatistics",
	initialState: {
		isLoading: true,
		isFetching: false,
		ticketStatisticsByMonth: {},
	} as TicketStatisticsState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTicketStatistics.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchTicketStatistics.fulfilled, (state, { payload }) => {
				state.ticketStatisticsByMonth[payload.period] = payload;
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

/**
 * Returns the entire keyed-by-month object
 */
export const selectTicketStatistics = (state: RootState) => state.ticketStatistics;

/**
 * Returns ticket statistics for a specific month.
 * Usage example: const januaryStats = useSelector(selectTicketStatisticsByMonth("2023-01"));
 */
export const selectTicketStatisticsByMonth = (month: string) => (state: RootState) =>
	state.ticketStatistics.ticketStatisticsByMonth[month] ||
	({
		tickets: {
			supportTickets: [] as Ticket[],
			escalatedTickets: [] as Ticket[],
			extendedSecurityTickets: [] as Ticket[],
		},
	} as TicketStatistics);

export default ticketsSlice.reducer;
