import { createAsyncThunk } from "@reduxjs/toolkit";
import { SecurityIncident, TicketStatistics } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost, apiPut } from "utilities/api";

export const fetchSecurityIncidents = createAsyncThunk(
	"securityIncidents/fetch",
	apiGet<SecurityIncident[]>(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.GET_SECURITY_INCIDENTS("Sentinel"),
		[SCOPES.FRESHSERVICE_READ],
	),
);

export const escalateSecurityIncident = createAsyncThunk(
	"securityIncidents/escalate",
	apiPost(
		(authConfig, { id }) => authConfig.FRESHSERVICE_API_ENDPOINTS.REPLY_TO_TICKET(id),
		[SCOPES.FRESHSERVICE_WRITE],
	),
);

export const closeSecurityIncident = createAsyncThunk(
	"securityIncidents/close",
	apiPut(
		(authConfig, { id }) => authConfig.FRESHSERVICE_API_ENDPOINTS.UPDATE_TICKET(id),
		[SCOPES.FRESHSERVICE_WRITE],
	),
);

export const fetchTicketStatistics = createAsyncThunk(
	"securityIncidents/fetchTicketStatistics",
	apiGet<TicketStatistics>(
		(authConfig, { from, to }) =>
			authConfig.FRESHSERVICE_API_ENDPOINTS.GET_TICKET_STATISTICS(from, to),
		[SCOPES.FRESHSERVICE_READ],
	),
);
