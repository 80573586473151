import { Grid, Typography } from "@mui/material";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectAggregatedData, selectCheckSignInDetails } from "features/aggregatedData";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import clsx from "clsx";
import { fetchSignins } from "actions/signinActions";
import { selectSigninLogState } from "features/signins";
import colors from "styles/utilities/_colors.scss";

import styles from "./SignInsCard.module.scss";

interface CardInputProps {
	cardStyle: string;
	openDrawer: () => void;
}

export const SignInsCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	const { dispatch, auth } = useAuth();
	const signInsAggregatedDateData = useAppSelector(selectCheckSignInDetails) ?? [];
	const { isLoading: isLoadingAggreateData } = useAppSelector(selectAggregatedData);
	const { isLoading, isFetching } = useAppSelector(selectSigninLogState);

	const labels = signInsAggregatedDateData.map((item: any) =>
		dayjs.unix(parseInt(item.Date, 10)).format("MM/DD"),
	);

	const handleOpenDrawer = () => {
		openDrawer();
		handleFetchSignins();
	};

	const handleFetchSignins = () => {
		const from = dayjs().subtract(1, "day").format("YYYY-MM-DD 00:00:00");
		const to = dayjs().format("YYYY-MM-DD 23:59:59");
		const params = { from, to };

		if (isLoading && !isFetching) dispatch(fetchSignins({ auth, params }));
	};

	const actualData = {
		labels,
		datasets: [
			{
				label: "Successful",
				data: signInsAggregatedDateData.map((item: any) => item.TotalSuccessLogons),
				borderColor: "#6cd699",
				backgroundColor: "#6cd699",
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
			{
				label: "Failed",
				data: signInsAggregatedDateData.map((item: any) => item.TotalFailedLogons),
				borderColor: "#fd9a82",
				backgroundColor: "#fd9a82",
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
			{
				label: "Interrupted",
				data: signInsAggregatedDateData.map((item: any) => item.TotalInterruptedLogons),
				borderColor: "#f5d4ac",
				backgroundColor: "#f5d4ac",
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
		],
	};

	const skeletonData = {
		labels: Array(30).fill(""),
		datasets: [
			{
				label: "Successful",
				data: fillWith30RandomNumbers(),
				borderColor: colors.grey200,
				backgroundColor: colors.grey200,
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
			{
				label: "Failed",
				data: fillWith30RandomNumbers(),
				borderColor: colors.grey200,
				backgroundColor: colors.grey200,
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
			{
				label: "Interrupted",
				data: fillWith30RandomNumbers(),
				borderColor: colors.grey200,
				backgroundColor: colors.grey200,
				tension: 0.15,
				pointRadius: 0,
				pointHoverRadius: 0,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: { display: false },
		},
		animation: { duration: 1200 },
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					color: "#000000",
					font: { family: "Barlow", size: 12 },
				},
				grid: {
					color: "rgba(0,0,0,0.1)",
					lineWidth: 0.5,
				},
			},
			x: {
				ticks: {
					color: "#000000",
					font: { family: "Barlow", size: 11 },
					maxRotation: 0,
					minRotation: 0,
				},
				grid: {
					display: false,
				},
			},
		},
		elements: {
			line: { borderWidth: 2 },
			point: { radius: 0 },
		},
	};

	return (
		<Grid
			className={clsx(cardStyle, { [styles.card]: true })}
			onClick={handleOpenDrawer}
			onMouseEnter={handleFetchSignins}
		>
			<Typography variant="body1">Sign-Ins</Typography>
			<div className={styles.chartWrapper}>
				<Line data={isLoadingAggreateData ? skeletonData : actualData} options={options} />
			</div>
		</Grid>
	);
};

const fillWith30RandomNumbers = () => {
	const result = [];
	let current = Math.floor(Math.random() * 100); // Start with a random number between 0 and 100

	for (let i = 0; i < 30; i++) {
		// Add the current number to the result
		result.push(current);

		const isSignificantJump = Math.random() < 0.05; // 5% chance of a significant jump
		const change = isSignificantJump
			? Math.floor((Math.random() - 0.5) * 50) // Larger change between -25 to +25
			: Math.floor((Math.random() - 0.5) * 10); // Smaller change between -5 to +5

		current = Math.max(0, Math.min(100, current + change)); // Keep within 0-100 range
	}

	return result;
};
