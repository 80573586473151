import { Grid, Skeleton, Typography } from "@mui/material";
import { fetchTicketStatistics } from "actions/securityIncidentActions";
import {
	selectTicketStatistics,
	selectTicketStatisticsByMonth,
} from "features/tickets/ticketStatistics";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import colors from "styles/utilities/_colors.scss";
import styles from "./SupportTicketsCard.module.scss";
import dayjs from "dayjs";

interface CardInputProps {
	cardStyle: string;
	openDrawer: () => void;
}

// Register components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const SupportTicketsCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	const { isLoading } = useAppSelector(selectTicketStatistics);
	const ticketStatistics = useAppSelector(
		selectTicketStatisticsByMonth(dayjs().format("MMMM YYYY")),
	);

	useApiOnce(fetchTicketStatistics, useAppSelector(selectTicketStatistics));

	const skeletonData = {
		labels: ["Support", "Extended security", "Escalated"],
		datasets: [
			{
				label: "Number of Tickets",
				borderWidth: 0,
				categoryPercentage: 0.6,
				barPercentage: 0.8,
				data: [10, 30, 20],
				backgroundColor: [colors.grey200, colors.grey200, colors.grey200],
			},
		],
	};

	const actualData = {
		labels: ["Support", "Extended security", "Escalated"],
		datasets: [
			{
				label: "Number of Tickets",
				borderWidth: 0,
				categoryPercentage: 0.6,
				barPercentage: 0.8,
				data: [
					ticketStatistics?.supportTickets || 0,
					ticketStatistics?.extendedSecurityTickets || 0,
					ticketStatistics?.escalatedTickets || 0,
				],
				backgroundColor: ["#0D70CD", "#FFDD7C", "#FFAA44"],
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: {
			delay: 1000,
			duration: 2500,
		},
		scales: {
			x: {
				offset: true,
				grid: {
					display: false,
					drawBorder: false,
				},
				border: {
					display: false,
				},
				ticks: {
					display: false,
				},
			},
			y: {
				beginAtZero: true,
				grid: {
					display: true,
					drawBorder: false,
				},
				border: {
					display: false,
				},
				ticks: {
					color: "#606060",
					maxTicksLimit: 7,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
		},
	};

	const data = isLoading ? skeletonData : actualData;

	return (
		<Grid className={cardStyle} onClick={openDrawer}>
			<Grid container className={styles.cardContent}>
				<Grid container item className={styles.leftColumn}>
					<Grid container className={styles.cardHeader}>
						<Typography variant="body1">Support tickets</Typography>
					</Grid>
					<Grid item>
						{isLoading ? (
							<Skeleton variant="text" width={120} height={50} />
						) : (
							<>
								<Typography
									variant="h1"
									fontWeight={500}
									className={styles.totalTicketsNumber}
								>
									{ticketStatistics?.total}
								</Typography>
								<Typography variant="description" className={styles.caption}>
									Total tickets {ticketStatistics?.period?.toLocaleLowerCase()}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item className={styles.labelsContainer}>
						<Label
							isLoading={isLoading}
							color="#FFDD7C"
							text="Extended security"
							value={ticketStatistics?.extendedSecurityTickets}
						/>
						<Label
							isLoading={isLoading}
							color="#0D70CD"
							text="Support"
							value={ticketStatistics?.supportTickets}
						/>
						<Label
							isLoading={isLoading}
							color="#FFAA44"
							text="Escalated"
							value={ticketStatistics?.escalatedTickets}
						/>
					</Grid>
				</Grid>
				<Grid container item className={styles.rightColumn}>
					<div className={styles.chartContainer}>
						<Bar data={data} options={options} />
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface LabelProps {
	isLoading: boolean;
	color: string;
	text: string;
	value: number;
}

const Label = ({ isLoading, color, text, value }: LabelProps) => {
	return isLoading ? (
		<>
			<Skeleton variant="text" width={100} height={30} />
		</>
	) : (
		<>
			<Grid container className={styles.labelRow}>
				<div className={styles.colorDot} style={{ backgroundColor: color }} />
				<Typography variant="body1" className={styles.label}>
					{text}
				</Typography>
				<Typography variant="body1" className={styles.labelValue}>
					({value})
				</Typography>
			</Grid>
		</>
	);
};
