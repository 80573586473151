import { Grid, Skeleton, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useAppSelector } from "hooks/hooks";
import {
	selectAggregatedData,
	selectAllAdminRolesWithoutMFA,
	selectCredentialRegistrationCheckValues,
} from "features/aggregatedData";

import styles from "./UsersWithoutMFACard.module.scss";
import colors from "styles/utilities/_colors.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CardInputProps {
	cardStyle: string;
	openDrawer: () => void;
}

export const UsersWithoutMfaCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	const adminRolesWithoutMFA = useAppSelector(selectAllAdminRolesWithoutMFA);
	const credentialRegistration = useAppSelector(selectCredentialRegistrationCheckValues);
	const { isLoading } = useAppSelector(selectAggregatedData);

	const usersWithoutMFACalculation =
		credentialRegistration?.TotalUsers - credentialRegistration?.MembersWithMFA;

	const guestsWithoutMFACalculation =
		credentialRegistration?.TotalGuestUsers - credentialRegistration?.GuestsWithMFA;

	const allAdminsWithoutMFA =
		adminRolesWithoutMFA.flatMap((admin) => admin.MembersWithoutMFA) ?? [];
	const adminsWithoutMFA = !isNaN(allAdminsWithoutMFA.length) ? allAdminsWithoutMFA.length : 0;
	const usersWithoutMFA = !isNaN(usersWithoutMFACalculation) ? usersWithoutMFACalculation : 0;
	const guestsWithoutMFA = !isNaN(guestsWithoutMFACalculation) ? guestsWithoutMFACalculation : 0;

	const data = {
		labels: ["Admins", "Users", "Guests"],
		datasets: [
			{
				data: [adminsWithoutMFA ?? 0, usersWithoutMFA ?? 0, guestsWithoutMFA ?? 0],
				backgroundColor: [
					colors.dashboardRed,
					colors.dashboardBlue,
					colors.dashboardYellow,
				],
				hoverOffset: 4,
				borderWidth: 0, // Optional: Removes borders between sections if present
				cutout: "60%", // Adjust this value to make the doughnut thinner
			},
		],
	};

	const totalUsersPlugin = {
		id: "totalUsersText",
		beforeDraw: (chart: any) => {
			const width = chart.width;
			const height = chart.height;
			const ctx = chart.ctx;
			ctx.restore();

			ctx.font = `bold 20px Barlow`;
			ctx.textBaseline = "middle";
			ctx.fillStyle = "#333333";

			const totalUsers = chart.data.datasets[0].data.reduce(
				(acc: number, curr: number) => acc + curr,
				0,
			) as number;
			const numberText = totalUsers.toString();
			const numberTextX = Math.round((width - ctx.measureText(numberText).width) / 2);
			const numberTextY = height / 2 - 10; // Adjust position up to make room for the "users" text

			ctx.fillText(numberText, numberTextX, numberTextY);

			// Additional text for "users"
			ctx.font = `14px Barlow`;
			ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
			const usersText = "users";
			const usersTextX = Math.round((width - ctx.measureText(usersText).width) / 2);
			const usersTextY = height / 2 + 10; // Position below the number

			ctx.fillText(usersText, usersTextX, usersTextY);
			ctx.save();
		},
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false, // This will hide the legend
			},
			tooltip: {
				enabled: true,
				mode: "point" as const,
				intersect: false,
				bodyFont: {
					size: 14,
				},
				padding: 20,
				caretPadding: 10,
				caretSize: 10,
				backgroundColor: "rgba(0, 0, 0, 0.8)",
			},
		},
	};

	return (
		<Grid className={cardStyle} onClick={openDrawer}>
			<Typography variant="body1">Users without MFA</Typography>
			<Grid container className={styles.cardContent}>
				<Grid item className={styles.doughtnutContainer}>
					<Doughnut
						data={data}
						options={options}
						className={styles.doughnut}
						plugins={[totalUsersPlugin]}
					/>
				</Grid>
				<Grid item className={styles.labelContainer}>
					<Label
						isLoading={isLoading}
						color={colors.dashboardRed}
						text="Admins"
						value={adminsWithoutMFA ?? 0}
					/>
					<Label
						color={colors.dashboardBlue}
						text="Users"
						value={usersWithoutMFA ?? 0}
						isLoading={isLoading}
					/>
					<Label
						isLoading={isLoading}
						color={colors.dashboardYellow}
						text="Guests"
						value={guestsWithoutMFA ?? 0}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface LabelProps {
	isLoading: boolean;
	color: string;
	text: string;
	value: number;
}

const Label = ({ isLoading, color, text, value }: LabelProps) => {
	return isLoading ? (
		<>
			<Skeleton variant="text" width={100} height={28} />
		</>
	) : (
		<>
			<Grid container className={styles.labelRow}>
				<div className={styles.colorDot} style={{ backgroundColor: color }} />
				<Typography variant="body1" className={styles.label}>
					{text}
				</Typography>
				<Typography variant="body1" className={styles.labelValue}>
					({value})
				</Typography>
			</Grid>
		</>
	);
};
