import { Drawer, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { selectDrawerType, setDashboardDrawer } from "features/dashboard";
import { useAppSelector, useAuth } from "hooks/hooks";
import { SecurityIncidentsDrawerContent } from "./DrawerContent/SecurityIncidentsDrawerContent";
import { DashboardDrawerType } from "utilities/constants/enums";
import { RiskDetectionsDrawerContent } from "./DrawerContent/RiskDetectionsDrawerContent";
import { SupportTicketsDrawerContent } from "./DrawerContent/SupportTicketsDrawerContent/SupportTicketsDrawerContent";
import { SignInsDrawerContent } from "./DrawerContent/SignInsDrawerContent";
import { UsersWithoutMFADrawerContent } from "./DrawerContent/UsersWithoutMFADrawerContent";
import { SharepointDrawerContent } from "./DrawerContent/SharepointDrawerContent/SharepointDrawerContent";
import {
	securityIncidentSelectors,
	selectSecurityIncidents,
} from "features/tickets/securityIncidents";
import {
	selectAggregatedData,
	selectAnyMFADetailsFound,
	selectRiskDetectionDetails,
} from "features/aggregatedData";
import { selectTicketStatistics } from "features/tickets/ticketStatistics";
import { selectSigninLogState, signInsSelector } from "features/signins";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingDrawerView } from "components/Dashboard/DashboardDrawer/LoadingDrawer";
import { ExchangeDrawerContent } from "./DrawerContent/ExchangeDrawerContent";

import styles from "./DashboardDrawer.module.scss";
import { useEffect, useState } from "react";
import {
	selectAnyExchangeData,
	selectAnySharepointData,
	selectExchangeDataState,
	selectSharePointState,
} from "features/officeData";
import { NoDataFoundDrawerView } from "./NoDataFoundDrawer";

export const DashboardDrawer = () => {
	const drawerType = useAppSelector(selectDrawerType);
	const isDrawerOpen = drawerType !== null;
	const { dispatch } = useAuth();

	// Limits to show either skeleton loader, or the actual content (including the loading state and the actual data)
	//
	const [isWithinSkeletonLimit, setIsWithinSkeletonLimit] = useState(true);
	useEffect(() => {
		setTimeout(() => setIsWithinSkeletonLimit(false), 200);

		return () => {
			setIsWithinSkeletonLimit(true);
		};
	}, [isDrawerOpen]);
	const { isLoading: isSecurityIncidentsLoading } = useAppSelector(selectSecurityIncidents);
	const { isLoading: isAggregatedDataLoading } = useAppSelector(selectAggregatedData); // risk detections + users without mfa
	const { isLoading: isSupportTicketsLoading } = useAppSelector(selectTicketStatistics);
	const { isLoading: isSignInsLoading } = useAppSelector(selectSigninLogState);
	const { isLoading: isSharepointLoading } = useAppSelector(selectSharePointState);
	const { isLoading: isExchangeLoading } = useAppSelector(selectExchangeDataState);

	const drawerTypeIsLoading = {
		[DashboardDrawerType.SecurityIncidents]: isSecurityIncidentsLoading,
		[DashboardDrawerType.RiskDetections]: isAggregatedDataLoading,
		[DashboardDrawerType.Tickets]: isSupportTicketsLoading,
		[DashboardDrawerType.SignIns]: isSignInsLoading,
		[DashboardDrawerType.UsersWithoutMfa]: isAggregatedDataLoading,
		[DashboardDrawerType.Sharepoint]: isSharepointLoading,
		[DashboardDrawerType.Exchange]: isExchangeLoading,
	} as Record<DashboardDrawerType, boolean>;

	const anySecurityIncidents = useAppSelector(securityIncidentSelectors.selectAll)?.length > 0;
	const anySharepointData = useAppSelector(selectAnySharepointData);
	const anyExchangeData = useAppSelector(selectAnyExchangeData);
	const anyRiskDetections =
		useAppSelector(selectRiskDetectionDetails)?.RiskDetections?.length > 0;
	const anySignIns = useAppSelector(signInsSelector.selectAll)?.length > 0;
	const anyUsersWithoutMfa = useAppSelector(selectAnyMFADetailsFound);

	const dataLoaded = {
		[DashboardDrawerType.SecurityIncidents]:
			anySecurityIncidents && !isSecurityIncidentsLoading && !isWithinSkeletonLimit,
		[DashboardDrawerType.RiskDetections]:
			anyRiskDetections && !isAggregatedDataLoading && !isWithinSkeletonLimit,
		[DashboardDrawerType.Tickets]: true, // Always show something in the ticket drawer, as the user can navigate between periods withing the drawer
		[DashboardDrawerType.SignIns]: anySignIns && !isSignInsLoading && !isWithinSkeletonLimit,
		[DashboardDrawerType.UsersWithoutMfa]:
			anyUsersWithoutMfa && !isAggregatedDataLoading && !isWithinSkeletonLimit,
		[DashboardDrawerType.Sharepoint]:
			anySharepointData && !isSharepointLoading && !isWithinSkeletonLimit,
		[DashboardDrawerType.Exchange]:
			anyExchangeData && !isExchangeLoading && !isWithinSkeletonLimit,
	} as Record<DashboardDrawerType, boolean>;

	const handleClose = () => {
		dispatch(setDashboardDrawer(null));
	};

	if (!drawerType) return null;

	return (
		<Drawer
			anchor="right"
			open={isDrawerOpen}
			onClose={handleClose}
			PaperProps={{ className: styles.drawer }}
			className={styles.dashboardDrawerWrapper}
			aria-labelledby="dashboard-drawer"
			disableEnforceFocus
		>
			<IconButton onClick={handleClose} className={styles.closeButton}>
				<CloseIcon />
			</IconButton>
			<Typography variant="h2" className={styles.drawerTitle}>
				{drawerType.replace(/([A-Z])/g, " $1").trim()}
			</Typography>
			{!isWithinSkeletonLimit && drawerTypeIsLoading[drawerType] ? (
				<LoadingDrawerView drawerType={drawerType} />
			) : isWithinSkeletonLimit ? (
				<Grid container className={styles.skeletonContainer}>
					<Skeleton variant="rectangular" className={styles.loadingSkeleton} />
				</Grid>
			) : dataLoaded[drawerType] ? (
				DashboardDrawerContent[drawerType]
			) : (
				<NoDataFoundDrawerView drawerType={drawerType} />
			)}
		</Drawer>
	);
};

const DashboardDrawerContent = {
	[DashboardDrawerType.SecurityIncidents]: <SecurityIncidentsDrawerContent />,
	[DashboardDrawerType.RiskDetections]: <RiskDetectionsDrawerContent />,
	[DashboardDrawerType.Tickets]: <SupportTicketsDrawerContent />,
	[DashboardDrawerType.SignIns]: <SignInsDrawerContent />,
	[DashboardDrawerType.UsersWithoutMfa]: <UsersWithoutMFADrawerContent />,
	[DashboardDrawerType.Sharepoint]: <SharepointDrawerContent />,
	[DashboardDrawerType.Exchange]: <ExchangeDrawerContent />,
} as Record<DashboardDrawerType, JSX.Element>;
