import { Grid, Skeleton } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import { User } from "types";
import { TABS as USER_DRAWER_TABS } from "components/MyPeople/UserDrawer/UserDrawerTabSelector";

interface UserCellProps {
	id: string;
	UPN: string;
	users: Dictionary<User>;
	isUsersLoading: boolean;
}

export const UserCell = ({ id, UPN, users, isUsersLoading }: UserCellProps) => {
	const getDeviceOwner = (userId: string, UPN: string) => {
		let foundUser = users[userId as keyof User] ?? ({} as User);

		if (!foundUser && UPN) {
			foundUser =
				Object.values(users).find((user) => user?.userPrincipalName === UPN) ??
				({} as User);
		}

		return foundUser;
	};

	const user = getDeviceOwner(id, UPN);

	return isUsersLoading ? (
		<Skeleton variant="text" width={100} />
	) : (
		<Grid item>
			<UserInfoDisplay
				id={user.id}
				initialTab={USER_DRAWER_TABS.DEVICES}
				name={user.displayName}
				mail={user.mail}
				variant={{ name: "body1", mail: "caption" }}
				maxCharLength={{ name: 25, mail: 35 }}
			/>
		</Grid>
	);
};
