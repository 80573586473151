import { Grid, Typography } from "@mui/material";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { fetchSharePointStorageData } from "actions/officeDataActions";
import { selectSharePointState, selectSharePointStatistics } from "features/officeData";
import { ReactComponent as SharepointIcon } from "assets/microsoftIcons/Sharepoint.svg";

import styles from "./SharepointCard.module.scss";
import clsx from "clsx";
import { SharePointRiskLevel } from "utilities/constants/enums";

interface CardInputProps {
	cardStyle: string;
	openDrawer: () => void;
}

export const SharepointCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	useApiOnce(fetchSharePointStorageData, useAppSelector(selectSharePointState));
	const { isLoading } = useAppSelector(selectSharePointState);
	const sharePointStatistics = useAppSelector(selectSharePointStatistics);
	const riskStyle = `${sharePointStatistics.usageRisk.toLowerCase()}Risk`;

	return (
		<Grid className={cardStyle} onClick={openDrawer}>
			<Grid item className={styles.sharepoint}>
				<Grid item className={styles.sharepointHeader}>
					<Typography variant="body1">Sharepoint storage</Typography>
					<SharepointIcon className={styles.sharepointIcon} />
				</Grid>
				<Grid item>
					<Typography variant="description" display="inline" className={styles.text}>
						Storage used:
					</Typography>
					<Typography variant="description" display="inline" className={styles.number}>
						{sharePointStatistics.usedStorageGB} GB
					</Typography>
					<Typography variant="description" display="inline" className={styles.text}>
						of
					</Typography>
					<Typography variant="description" display="inline" className={styles.number}>
						{sharePointStatistics.totalStorageGB} GB
					</Typography>
				</Grid>
				<div className={styles.progressBarContainer}>
					{/* Main Progress Bar */}
					<div
						className={clsx(styles.progressBar, {
							[styles[riskStyle]]: true,
						})}
						style={{
							width: `${Math.min(sharePointStatistics.percentOfStorageUsed, 100)}%`,
						}}
					></div>

					{/* Overflow Bar */}
					{sharePointStatistics.percentOfStorageUsed > 100 && (
						<div
							className={styles.overflowBar}
							style={{
								width: `${sharePointStatistics.percentOfStorageUsed - 100}%`,
							}}
						></div>
					)}
				</div>

				<Grid item container>
					<div
						className={clsx({
							[styles.colorDot]: !isLoading,
							[styles[riskStyle]]: true,
						})}
					></div>
					<Typography
						variant="caption"
						className={clsx({
							[styles.criticalText]:
								sharePointStatistics.usageRisk === SharePointRiskLevel.Critical,
						})}
					>
						{sharePointStatistics.usageRiskText}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
