import { Grid, Skeleton, Typography } from "@mui/material";

import styles from "./InsightCard.module.scss";
import { EastOutlined } from "@mui/icons-material";
import { useAppNavigate } from "hooks/useAppNavigate";
import clsx from "clsx";

interface InsightCardProps {
	title: string;
	icon: React.ReactNode;
	value: string | number;
	isLoading: boolean;
	per?: string;
	trend?: "up" | "down";
	trendConsideredPositive?: boolean;
	trendValue?: string;
	secondaryText?: string;
	linkToPage?: string;
}

export const InsightCard = ({
	title,
	icon,
	value,
	isLoading,
	per,
	trend,
	trendConsideredPositive,
	trendValue,
	secondaryText,
	linkToPage,
}: InsightCardProps) => {
	const trendColor = trendConsideredPositive ? "#1CB769" : "#F93C65";
	const zeroTrend = trendValue === "0 %";
	const { navigateToPage } = useAppNavigate();

	return (
		<Grid
			item
			className={clsx(styles.insightCard, { [styles.clickable]: linkToPage })}
			onClick={() => linkToPage && navigateToPage(linkToPage)}
		>
			<Grid container className={styles.header}>
				<Typography variant="body1" className={styles.insightHeader}>
					{title}
				</Typography>
				{linkToPage && <EastOutlined className={styles.icon} />}
			</Grid>
			<Grid item className={styles.value}>
				{icon}
				<Grid container item className={styles.valueTextContainer}>
					{isLoading ? (
						<Skeleton
							variant="rectangular"
							height={32}
							width={"80%"}
							className={styles.skeleton}
						/>
					) : (
						<>
							<Typography variant="h2" display={"inline"} fontWeight={600}>
								{value}
							</Typography>
							<Typography variant="body1" display={"inline"}>
								{per}
							</Typography>
						</>
					)}
				</Grid>
			</Grid>
			<Grid item className={styles.footer}>
				{isLoading ? (
					<Skeleton
						variant="rectangular"
						height={14}
						width={"100%"}
						className={styles.skeleton}
					/>
				) : zeroTrend ? (
					<Typography variant="description" color="primary">
						No change from 30 days ago
					</Typography>
				) : (
					<>
						{trend && (trend === "up" ? upSVG(trendColor) : downSVG(trendColor))}
						<Typography
							variant="description"
							fontWeight={500}
							pl={trend ? 1 : 0}
							pr={trend ? 0.5 : 0}
						>
							{trendValue}
						</Typography>
						<Typography variant="description" color="primary">
							{secondaryText}
						</Typography>
					</>
				)}
			</Grid>
		</Grid>
	);
};

const downSVG = (trendColor: string) => (
	<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 12L16.29 9.71L11.41 4.83L7.41 8.83L0 1.41L1.41 0L7.41 6L11.41 2L17.71 8.29L20 6V12H14Z"
			fill={trendColor}
		/>
	</svg>
);

const upSVG = (trendColor: string) => (
	<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
			fill={trendColor}
		/>
	</svg>
);
