import { Grid, Skeleton, Typography } from "@mui/material";
import { fetchExchangeData } from "actions/officeDataActions";
import { selectExchangeDataState, selectExchangeDataStatistics } from "features/officeData";
import { useApiOnce, useAppSelector } from "hooks/hooks";

import styles from "./ExchangeCard.module.scss";
import clsx from "clsx";

interface CardInputProps {
	cardStyle: string;
	openDrawer: () => void;
}

export const ExchangeCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	const exchangeState = useAppSelector(selectExchangeDataState);
	useApiOnce(fetchExchangeData, exchangeState);
	const exchangeDataStatistics = useAppSelector(selectExchangeDataStatistics);

	return (
		<Grid item className={clsx(cardStyle, styles.exchangeCard)} onClick={openDrawer}>
			<Grid container className={styles.exchangeHeader}>
				<Typography variant="body1">Exchange</Typography>
				{ExchangeIcon}
			</Grid>
			<Grid item>
				{exchangeState?.isLoading ? (
					<Skeleton variant="text" width={35} height={32} />
				) : (
					<Typography variant="h3" fontWeight={500} mt={1}>
						{exchangeDataStatistics.totalMailboxes}
					</Typography>
				)}
				<Typography variant="body1" className={styles.underTitle}>
					Total mailboxes
				</Typography>
			</Grid>
			<Grid item container mt={1}>
				<Typography variant="body1">Storage quota status</Typography>
				<Grid container className={styles.exchangeContent}>
					<Grid item className={styles.quotaInfo}>
						{exchangeState?.isLoading ? (
							<Skeleton variant="text" width={35} height={28} />
						) : (
							<Typography
								variant="body1"
								className={clsx({
									[styles.overQuota]:
										exchangeDataStatistics.overQuotaMailboxes > 0,
								})}
							>
								{exchangeDataStatistics.overQuotaMailboxes}{" "}
							</Typography>
						)}
						<Typography variant="description" className={styles.numberExplanation}>
							over quota
						</Typography>
					</Grid>

					<Grid item className={styles.quotaInfo}>
						{exchangeState?.isLoading ? (
							<Skeleton variant="text" width={35} height={28} />
						) : (
							<Typography
								variant="body1"
								className={clsx({
									[styles.warningQuota]:
										exchangeDataStatistics.warningQuotaMailboxes > 0,
								})}
							>
								{exchangeDataStatistics.warningQuotaMailboxes}{" "}
							</Typography>
						)}
						<Typography variant="description" className={styles.numberExplanation}>
							warning quota
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const ExchangeIcon = (
	<svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_16424_10772)">
			<path
				d="M27.1602 1.15233e-08H20.7238C20.4821 -2.71879e-05 20.2428 0.0480967 20.0195 0.141621C19.7963 0.235146 19.5934 0.372238 19.4226 0.545061L4.23052 15.9047C4.05959 16.0774 3.92399 16.2825 3.83149 16.5082C3.73898 16.7339 3.69138 16.9759 3.69141 17.2202V23.7276C3.69139 23.972 3.73898 24.2139 3.83146 24.4397C3.92394 24.6654 4.05949 24.8705 4.23038 25.0433C4.40128 25.2161 4.60415 25.3531 4.82744 25.4466C5.05072 25.5401 5.29003 25.5882 5.53171 25.5882H11.9681C12.2098 25.5883 12.4491 25.5401 12.6724 25.4466C12.8956 25.3531 13.0985 25.216 13.2693 25.0432L28.4616 9.68355C28.8067 9.33464 29.0005 8.86143 29.0005 8.36803V1.86062C29.0005 1.61628 28.9529 1.37433 28.8604 1.14858C28.768 0.922835 28.6324 0.717717 28.4615 0.54494C28.2906 0.372163 28.0877 0.235111 27.8645 0.141609C27.6412 0.0481079 27.4019 -1.11906e-05 27.1602 1.15233e-08Z"
				fill="#28A8EA"
			/>
			<path
				d="M27.1601 25.5881H20.7235C20.2355 25.5881 19.7674 25.392 19.4223 25.0431L16.3457 21.9326V16.1204C16.3457 15.2382 16.6923 14.3921 17.3094 13.7682C17.9264 13.1444 18.7633 12.7939 19.6359 12.7939H25.3847L28.4613 15.9045C28.8064 16.2534 29.0002 16.7266 29.0002 17.22V23.7276C29.0002 24.221 28.8064 24.6942 28.4613 25.0431C28.1162 25.392 27.6481 25.5881 27.1601 25.5881Z"
				fill="#707070"
			/>
			<path
				d="M5.53159 0H11.9681C12.4562 7.53955e-07 12.9242 0.196015 13.2693 0.544923L16.346 3.65546V9.46765C16.346 10.3499 15.9993 11.196 15.3823 11.8198C14.7653 12.4437 13.9284 12.7941 13.0558 12.7941H7.30699L4.23038 9.68358C3.88528 9.33467 3.69141 8.86145 3.69141 8.36802V1.86048C3.69141 1.36705 3.88528 0.893829 4.23038 0.544922C4.57548 0.196014 5.04354 0 5.53159 0Z"
				fill="#707070"
			/>
			<path
				opacity="0.2"
				d="M15.2914 6.5732V20.6147C15.2926 20.6863 15.2855 20.7578 15.2703 20.8278C15.2224 21.1162 15.0746 21.378 14.8534 21.5665C14.6323 21.755 14.3521 21.8578 14.0628 21.8568H3.69141V5.33106H14.0628C14.2242 5.33077 14.3841 5.3627 14.5333 5.42502C14.6825 5.48735 14.818 5.57883 14.9322 5.69423C15.0463 5.80963 15.1368 5.94668 15.1985 6.09751C15.2601 6.24834 15.2917 6.40999 15.2914 6.5732Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M15.8187 6.57308V19.5484C15.8143 20.0178 15.6279 20.4668 15.2996 20.7988C14.9712 21.1307 14.5272 21.3192 14.0628 21.3236H3.69141V4.79785H14.0628C14.528 4.79949 14.9737 4.98705 15.3026 5.31962C15.6315 5.65218 15.8171 6.10276 15.8187 6.57308Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.2914 6.57319V19.5485C15.2917 19.7117 15.2601 19.8733 15.1985 20.0242C15.1368 20.175 15.0463 20.312 14.9322 20.4274C14.8181 20.5428 14.6825 20.6343 14.5333 20.6966C14.3841 20.759 14.2242 20.7909 14.0628 20.7906H3.69141V5.33106H14.0628C14.2242 5.33077 14.3841 5.3627 14.5333 5.42502C14.6825 5.48735 14.8181 5.57883 14.9322 5.69423C15.0463 5.80963 15.1368 5.94668 15.1985 6.09751C15.2601 6.24834 15.2917 6.40999 15.2914 6.57319Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M14.7641 6.57319V19.5485C14.7644 19.7117 14.7328 19.8733 14.6712 20.0242C14.6096 20.175 14.5191 20.312 14.4049 20.4274C14.2908 20.5428 14.1552 20.6343 14.006 20.6966C13.8569 20.759 13.697 20.7909 13.5355 20.7906H3.69141V5.33106H13.5355C13.697 5.33077 13.8569 5.3627 14.006 5.42502C14.1552 5.48735 14.2908 5.57883 14.4049 5.69423C14.5191 5.80963 14.6096 5.94668 14.6712 6.09751C14.7328 6.24834 14.7644 6.40999 14.7641 6.57319Z"
				fill="black"
			/>
			<path
				d="M13.5333 5.33105H1.2303C0.550825 5.33105 0 5.88795 0 6.57493V19.0137C0 19.7006 0.550825 20.2575 1.2303 20.2575H13.5333C14.2128 20.2575 14.7636 19.7006 14.7636 19.0137V6.57493C14.7636 5.88795 14.2128 5.33105 13.5333 5.33105Z"
				fill="#707070"
			/>
			<path
				d="M9.80042 10.0649H6.34826V12.1059H9.58853V13.4139H6.34826V15.5338H9.98438V16.8363H4.78125V8.75098H9.80041L9.80042 10.0649Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_16424_10772">
				<rect width="29" height="25.5882" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
