import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	EXCHANGE_STORAGE_DATA_FOLDER_PATH,
	SCOPES,
	SHAREPOINT_STORAGE_DATA_FOLDER_PATH,
} from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { ExchangeMailboxDataResponse, SharePointStorageDataResponse } from "types";

export const fetchSharePointStorageData = createAsyncThunk(
	"cost/fetchSharePointStorageData",
	apiGet<SharePointStorageDataResponse>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(
				SHAREPOINT_STORAGE_DATA_FOLDER_PATH,
			),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const fetchExchangeData = createAsyncThunk(
	"cost/fetchExchangeData",
	apiGet<ExchangeMailboxDataResponse>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(EXCHANGE_STORAGE_DATA_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);
