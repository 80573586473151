import { Grid, Typography } from "@mui/material";
import { selectUserDrawerState, setActiveTab, setUserDrawerOpen } from "features/user";
import { usersSelectors } from "features/users";
import { useAppSelector, useAuth } from "hooks/hooks";

import styles from "./UserInfoDisplay.module.scss";
import { fetchUser, fetchUserDevices } from "actions/userActions";
import { TABS as USER_DRAWER_TABS } from "components/MyPeople/UserDrawer/UserDrawerTabSelector";
import { TruncatableTypography } from "../TruncateableTypography";

interface UserDisplayInfoProps {
	id: string;
	initialTab?: number; // Use USER_DRAWER_TABS as values
	name?: string;
	mail?: string;
	variant?: {
		name?: any;
		mail?: any;
	};
	showMail?: boolean;
	maxCharLength?: {
		name: number;
		mail: number;
	};
}

// User display info component reused in multiple places
// Either fetches name / other info based on ID, or uses the passed in props
// The users shown in this component *might* be deleted/unavailable, so we can't rely on the store completely
export const UserInfoDisplay = ({
	id,
	name,
	mail,
	variant,
	showMail = true,
	initialTab = USER_DRAWER_TABS.USER_DETAILS,
	maxCharLength,
}: UserDisplayInfoProps): JSX.Element => {
	const { auth, dispatch } = useAuth();
	const user = useAppSelector((state) => usersSelectors.selectById(state, id));
	const { isDrawerOpen } = useAppSelector(selectUserDrawerState);

	const handleMouseEnter = () => {
		const shouldFetchUser = initialTab === USER_DRAWER_TABS.USER_DETAILS || !user;
		const shouldFetchDevices = initialTab === USER_DRAWER_TABS.DEVICES && !user?.devices;
		if (shouldFetchUser) dispatch(fetchUser({ auth, id }));
		else if (shouldFetchDevices) dispatch(fetchUserDevices({ auth, id }));
	};

	const handleUserInfoClicked = () => {
		if (isDrawerOpen) dispatch(setUserDrawerOpen({ isDrawerOpen: false, activeUserId: "" }));
		if (!user) dispatch(fetchUser({ auth, id }));
		dispatch(setActiveTab(initialTab));
		dispatch(setUserDrawerOpen({ isDrawerOpen: true, activeUserId: id }));
	};

	let typographyContent = <></>;
	if (maxCharLength?.name) {
		typographyContent = (
			<>
				<TruncatableTypography
					maxCharLength={maxCharLength.name}
					className={styles.link}
					variant={variant?.name || "body1"}
				>
					{name || user?.displayName}
				</TruncatableTypography>
				{showMail && (
					<TruncatableTypography
						maxCharLength={maxCharLength.mail}
						variant={variant?.mail || "caption"}
						color="text.secondary"
						display="block"
						mt={0.5}
					>
						{mail || user?.mail}
					</TruncatableTypography>
				)}
			</>
		);
	} else {
		typographyContent = (
			<>
				<Typography variant={variant?.name || "body1"} className={styles.link}>
					{name || user?.displayName}
				</Typography>
				{showMail && (
					<Typography
						variant={variant?.mail || "caption"}
						color="text.secondary"
						display="block"
						mt={0.5}
					>
						{mail || user?.mail}
					</Typography>
				)}
			</>
		);
	}

	return (
		<Grid
			item
			className={styles.userInfoContainer}
			onMouseEnter={handleMouseEnter}
			onClick={handleUserInfoClicked}
		>
			{typographyContent}
		</Grid>
	);
};
