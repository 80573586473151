import { Grid, Typography } from "@mui/material";
import {
	selectAllAdminRolesWithoutMFA,
	selectCredentialRegistrationCheckValues,
} from "features/aggregatedData";
import { useAppSelector, useAuth } from "hooks/hooks";

import styles from "./UsersWithoutMFADrawerContent.module.scss";
import { UserInfoDisplay } from "components/Common/UserInfoDisplay";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { setUserAuthenticationMethodFilter } from "features/users";
import { UserAuthenticationMethod } from "utilities/constants/enums";

export const UsersWithoutMFADrawerContent = () => {
	const adminRolesWithoutMFA = useAppSelector(selectAllAdminRolesWithoutMFA);
	const credentialRegistration = useAppSelector(selectCredentialRegistrationCheckValues);

	const { dispatch } = useAuth();
	const { navigateToPage } = useAppNavigate();

	const handleNavigateToManageEmployees = () => {
		dispatch(setUserAuthenticationMethodFilter([UserAuthenticationMethod.None]));
		navigateToPage(NAVIGATION_NAMES.MY_PEOPLE.path);
	};

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.linkContainer}>
				<Typography
					variant="body1"
					className={styles.linkToManageEmployees}
					onClick={handleNavigateToManageEmployees}
				>
					Filter, sort and analyze users without MFA - go to Manage Employees
				</Typography>
			</Grid>
			<Typography variant="h2" mb={1}>
				Admins without MFA
			</Typography>
			{adminRolesWithoutMFA.length === 0 && (
				<Typography variant="description" mb={3} mt={1}>
					No admins without MFA
				</Typography>
			)}
			{adminRolesWithoutMFA.map((adminRole) => (
				<Grid container className={styles.usersContainer} key={adminRole.Role}>
					<Typography variant="h3">{adminRole.Role}</Typography>
					<Typography variant="description" className={styles.description}>
						{adminRole.Description}
					</Typography>
					<Grid container className={styles.usersList}>
						{adminRole.MembersWithoutMFA.map((member) => (
							<>
								<UserInfoDisplay
									id={member.id}
									mail={member.userPrincipalName}
									name={member.userDisplayName}
								/>
							</>
						))}
					</Grid>
				</Grid>
			))}
			<Typography variant="h2" mb={1}>
				Users without MFA
			</Typography>
			<Grid container>
				{credentialRegistration.UsersWithoutMfaDetails.map((user) => (
					<Grid container className={styles.usersContainer} key={user.id}>
						<UserInfoDisplay
							id={user.id}
							mail={user.userPrincipalName}
							name={user.userDisplayName}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};
