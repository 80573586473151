import { Grid, Skeleton, Typography } from "@mui/material";
import { fetchSecurityIncidents } from "actions/securityIncidentActions";
import clsx from "clsx";
import {
	securityIncidentSelectors,
	selectSecurityIncidents,
} from "features/tickets/securityIncidents";
import { useApiOnce, useAppSelector } from "hooks/hooks";

import styles from "./SecurityIncidentsCard.module.scss";
import { CUSTOMER_FACING_STATUS } from "utilities/constants/constants";

interface CardInputProps {
	cardStyle?: string;
	openDrawer: () => void;
}

export const SecurityIncidentsCard = ({ cardStyle, openDrawer }: CardInputProps) => {
	const securityIncidentsState = useAppSelector(selectSecurityIncidents);
	useApiOnce(fetchSecurityIncidents, securityIncidentsState);
	const allSecurityIncidents = useAppSelector(securityIncidentSelectors.selectAll);

	const numSecurityIncidents = allSecurityIncidents?.filter(
		(incident) => incident.customer_facing_status === CUSTOMER_FACING_STATUS.AWAITING_REPLY.id,
	).length;

	const isLoading = securityIncidentsState?.isLoading;

	return (
		<Grid className={cardStyle} onClick={openDrawer}>
			<Typography variant="body1">Security incidents</Typography>
			<Grid container className={styles.securityIncidentsContainer}>
				<div
					className={clsx({
						[styles.securityIncidents]: numSecurityIncidents > 0,
						[styles.noSecurityIncidents]: numSecurityIncidents === 0,
					})}
				/>
				{isLoading ? (
					<Skeleton variant="text" width={100} height={30} />
				) : (
					<>
						<Typography
							variant="h3"
							fontWeight={500}
							className={clsx({ [styles.aboveZero]: numSecurityIncidents > 0 })}
						>
							{numSecurityIncidents}
						</Typography>
						<Typography variant="description" className={styles.activeAlerts}>
							Active alerts
						</Typography>
					</>
				)}
			</Grid>
		</Grid>
	);
};
